export type SupportedLanguage = 'English' | 'Espanol';
export const supportedLanguages: SupportedLanguage[] = ['English', 'Espanol'];

export type OrderStatus =
  | 'IN_REVIEW'
  | 'COMPLETED'
  | 'ABANDONED'
  | 'CANCELED'
  | 'DELIVERED'
  | 'PROVIDE_PUMP'
  | 'REJECTED';

export const OrderStatues: OrderStatus[] = [
  'IN_REVIEW',
  'COMPLETED',
  'ABANDONED',
  'CANCELED',
  'DELIVERED',
  'PROVIDE_PUMP',
  'REJECTED',
];

export type InventoryTypeStatus = 'On Shelf' | 'In Transit' | 'Delivered';

export const InventoryStatus: InventoryTypeStatus[] = [
  'On Shelf',
  'In Transit',
  'Delivered',
];

export type I18nLanguage = 'en-US' | 'es-ES';
export const englishI18nLanguage: I18nLanguage = 'en-US';
export const spanishI18nLanguage: I18nLanguage = 'es-ES';
export const defaultI18nLanguage: I18nLanguage = englishI18nLanguage;

export const supportedI18nLanguage: I18nLanguage[] = [
  englishI18nLanguage,
  spanishI18nLanguage,
];
