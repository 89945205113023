export class Product {
  orderId: string = '';
  userId: number = -1;
  facilityId: number = -1;
  firstName: string = '';
  lastName: string = '';
  dateOfBirth: string = '';
  serialNumber: string = '';
  optIn: boolean = false;
  language: string = '';
  signatureEncodedImg: string = '';
}
