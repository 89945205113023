import Axios from 'axios';
import { apiUrl, apiVersion } from '../utils/Constants';
import { User } from '../models/User';

export const usersEndpoint = `${apiUrl}/api/v${apiVersion}`;

export const getUserList = async (facilityId: number) => {
  const { data } = await Axios.get<User[]>(
    `${usersEndpoint}/facilities/${facilityId}/users`
  );
  return data;
};

export const validatePin = async (userId: number, request: FormData) => {
  const { data } = await Axios.post(
    `${apiUrl}/api/authenticate/user/${userId}`,
    request
  );
  return data;
};

export const getHospitalName = async (facilityId: number) => {
  const { data } = await Axios.get(`${usersEndpoint}/facilities/${facilityId}`);
  return data;
};
