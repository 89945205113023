import { StoreAction } from '../Action';

const initialState = {
  shelf: [],
  loading: true,
};
const ShelfReducer = (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case StoreAction.GET_SHELF:
      return {
        ...state,
        shelf: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default ShelfReducer;
