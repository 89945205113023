/* eslint-disable react/jsx-no-duplicate-props */
import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import Compressor from 'compressorjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Modal,
  Container,
  Grid,
  TextField,
  InputLabel,
  Autocomplete,
  Button,
  Stack,
  FormHelperText,
  TextFieldProps,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Cookies } from 'react-cookie';
import { array, date, object, string } from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  createOrder,
  deleteFile,
  uploadFile,
} from '../../services/OrderService';
import {
  SupportedLanguage,
  supportedLanguages,
} from '../../common/utils/Constants';
import { TakePhoto } from '../../common/components/TakePhoto/TakePhoto';
import 'react-toastify/dist/ReactToastify.css';
import { changeI18nLanguage } from '../../common/utils/Utils';
import { SessionExpireModal } from '../SessionExpireModal/SessionExpireModal';
import {
  createOrderAction,
  getAllOrdersAction,
} from '../../common/components/Redux/Actions/OrdersAction';
import { NewUpdateVersionModal } from '../NewUpdateVersionModal/NewUpdateVersionModal';

const notify = (message: string) => {
  toast.error(message, { position: 'top-center' });
};

interface CreateOrderModalProps {
  isCreateOrderModalOpen: boolean;
  onClose: () => void;
}
interface FileProps {
  percentage?: number | boolean;
  fileName: string;
  index?: number;
  type: string;
  encodedImg?: string;
  fileKey: string;
  isError?: boolean;
}
class CreateOrderModalRequest {
  firstName: string = '';
  lastName: string = '';
  dateOfBirth: string = '';
  phoneNumber: string = '';
  language: SupportedLanguage | string = '';
  userId: number = -1;
  facilityId: number = -1;
  facesheetFiles: Array<[]> = [];
  prescriptionFiles: Array<[]> = [];
  files: Array<[]> = [];
}
const createOrderModalsx = {
  inputLabel: {
    color: '#4A6B89',
    fontSize: '12px',
    margin: '15px 0 5px ',
  },
  textField: {
    border: '0',
    margin: '0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#899dad',
      },
      '&:hover fieldset': {
        borderColor: '#899dad',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#899dad ',
      },
    },
  },
  input: {
    color: '#899dad',
    fontSize: '13px',
    backgroundColor: '#F5F6FA',
    fontWeight: '400 ',
    height: '40px ',
    margin: '0',
  },
  button: {
    background: '#4A6B89',
    color: '#fff',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  selectFiled: {
    '& .MuiOutlinedInput-notchedOutline': {
      '& ': {
        borderColor: '#899dad',
      },
      '&:hover': {
        borderColor: '#899dad',
      },
      '&.Mui-focused': {
        borderColor: '#899dad',
      },
    },
  },
  selectinput: {
    color: '#899dad ',
    fontSize: '13px ',
    backgroundColor: '#F5F6FA !important',
    fontWeight: '400 ',
    margin: '0 ',
    '&:focus': {
      backgroundColor: '#ffddec',
      borderColor: 'brown',
    },
    '&:before': {
      borderColor: 'orange',
    },
    '&:after': {
      borderColor: 'green',
    },
  },
  bottomButtons: {
    boxShadow: 'none ',
    fontSize: '13px',
    fontWeight: '700 ',
  },
  countryInput: {
    color: 'rgb(137, 157, 173)',
    fontSize: '13px',
    backgroundColor: 'rgb(245, 246, 250)',
    fontWeight: '400',
    height: '40px',
    width: '100%',
    border: '1px solid #899dad',
    borderRadius: '5px',
    padding: '10px',
  },
  attachementPreview: {
    position: 'relative',
    width: '50%',
    '.MuiTypography-root': {
      background: '#0000007a',
      color: '#fff',
      position: 'absolute',
      bottom: '7px',
      width: '100%',
      padding: '0px 10px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
  deleteIcon: {
    position: 'absolute',
    right: '3px',
    top: '3px',
    color: 'white',
    background: '#0000007a',
    borderRadius: '3px',
    zIndex: 1,
  },
  fileName: {
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btnStyle: {
    color: 'white',
    backgroundColor: '#4a6a89',
    borderRadius: '5px',
    padding: '6px 17px',
    fontSize: '12px',
    background: '#4a6a89',
    textTransform: 'capitalize',
    display: 'flex',
    '&:hover': {
      background: '#4a6a89',
      boxShadow: 'none',
    },
    marginBottom: 1.5,
  },
  btnStyleCapture: {
    color: 'white',
    width: '100%',
    backgroundColor: '#4a6a89',
    borderRadius: '5px',
    padding: '6px 17px',
    fontSize: '12px',
    background: '#4a6a89',
    textTransform: 'capitalize',
    display: 'flex',
    maring: 0,
    '&:hover': {
      background: '#4a6a89',
      boxShadow: 'none',
    },
  },
};

const previewImageStyle = {
  width: '100%',
  border: '2px solid #4A6B89',
  borderRadius: '5px',
};
const phoneRegex = /^(1\s?)?(\d{3}|\(\d{3}\))[\s-]?\d{3}[\s-]?\d{4}$/gm;

const textFieldProps: TextFieldProps = {
  size: 'small',
  fullWidth: true,
  margin: 'dense',
  sx: createOrderModalsx.textField,
  InputProps: {
    sx: createOrderModalsx.input,
  },
};

const attachmentFileTypes = ['png', 'bmp', 'jpeg', 'jpg', 'pdf'];
export const CreateOrderModal: FunctionComponent<CreateOrderModalProps> = ({
  isCreateOrderModalOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { state }: any = useLocation();
  const dispatch = useDispatch<any>();
  const facilityId = state?.facilityId
    ? state?.facilityId
    : secureLocalStorage.getItem('facilityId');
  const [isCancelModalOpen, setIsCancelModalOpen] =
    React.useState<boolean>(false);
  const cookies = new Cookies();

  const userId = state?.userId
    ? state?.userId
    : secureLocalStorage.getItem('userId');
  const [facesheetProgress, setFacesheetProgress] = useState<any>([]);
  const { serviceWorkerUpdated } = useSelector(
    (serviceWorkerState: any) => serviceWorkerState.ServiceWorkerReducer
  );
  const [prescriptionProgress, setPrescriptionProgress] = useState<any>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uuid, setUuid] = useState<string>('');
  const focusInput = useRef<null | HTMLInputElement>(null);
  const validationSchema = object({
    firstName: string().required(
      t('InPatient-AddInPatientOrder-FirstNamError')
    ),
    lastName: string().required(t('InPatient-AddInPatientOrder-LastNameError')),
    dateOfBirth: date()
      .typeError(t('DateOfBirthNull'))
      .max(new Date(), t('DateOfBirthNull'))
      .min(new Date().getFullYear() - 100, t('DateOfBirthNull'))
      .required(t('InPatient-AddInPatientOrder-DateOfBirthError')),
    phoneNumber: string()
      .required(t('MissingPhoneNumber'))
      .matches(phoneRegex, t('InvalidPhonePrompt')),
    facesheetFiles: array()
      .required(t('InPatient-AddInPatientOrder-FaceSheetUploadSelectText'))
      .min(1, t('InPatient-AddInPatientOrder-FaceSheetUploadSelectText')),
    prescriptionFiles: array()
      .required(t('InPatient-AddInPatientOrder-MissingPrescriptionError'))
      .min(1, t('InPatient-AddInPatientOrder-MissingPrescriptionError')),
    language: string()
      .required(t('NewLC-CreateOrder-LanguageRequired'))
      .oneOf(
        [null, ...supportedLanguages.map((option: string) => option)],
        t('NewLC-CreateOrder-LanguageRequired')
      ),
  });

  const formik = useFormik({
    initialValues: new CreateOrderModalRequest(),
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        setLoading((prevLoading) => !prevLoading);
        const prescriptionFileData = values.prescriptionFiles
          ?.filter((item: FileProps) => !item.isError)
          ?.map((file: FileProps) => {
            const newPrescriptionFile = { ...file };
            delete newPrescriptionFile?.encodedImg;
            delete newPrescriptionFile?.index;
            delete newPrescriptionFile?.isError;
            delete newPrescriptionFile?.percentage;
            return newPrescriptionFile;
          });
        const facesheetFileData = values?.facesheetFiles
          ?.filter((item: FileProps) => !item.isError)
          ?.map((file: FileProps) => {
            const newFacesheetFile = { ...file };
            delete newFacesheetFile?.encodedImg;
            delete newFacesheetFile?.index;
            delete newFacesheetFile?.isError;
            delete newFacesheetFile?.percentage;
            return newFacesheetFile;
          });

        const val = {
          ...values,
          facilityId,
          userId,
          phoneNumber: values?.phoneNumber?.replace(/[\s\-()]/g, ''),
          files: [...prescriptionFileData, ...facesheetFileData],
          dateOfBirth: moment(values.dateOfBirth).format('yyyy-MM-DD'),
        };
        const { facesheetFiles, prescriptionFiles, ...data } = val;

        const order = await createOrder(data);
        setLoading((prevLoading) => !prevLoading);
        if (order?.data) {
          dispatch(createOrderAction(order?.data));
          onClose();
          dispatch(getAllOrdersAction({ facilityId, t }));
        } else if (order?.error) {
          if (
            order?.error?.message !== 'timeout of 20000ms exceeded' &&
            order?.error?.message !== 'Network Error' &&
            order?.error?.response?.status !== 500
          ) {
            notify(order?.error?.response?.data?.message);
          } else {
            notify(t('NewLCOP-NetworkError'));
          }
        }
      } catch (error: any) {
        if (error.response.data.error === 'Unauthorized') {
          navigate(
            `/users?facilityId=${secureLocalStorage.getItem('facilityId')}`
          );
        }
        notify(error?.response?.data?.message);
        setLoading((prevLoading) => !prevLoading);
      }
    },
  });
  const getFieldHelperText = (
    field: keyof CreateOrderModalRequest
  ): string | any | undefined =>
    formik.touched[field] ? formik.errors[field] : undefined;

  const getBase64 = (files: any) =>
    new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null = '';
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  const handleCloseCancelModal = (val: boolean) => {
    if (val === true) {
      if (onClose) {
        onClose();
      }
      formik.setFieldValue('facesheetFiles', []);
      formik.setFieldValue('prescriptionFiles', []);
    }
    setIsCancelModalOpen(false);
  };
  const fileUpload = async (
    index: number,
    field: string,
    data: File | Blob
  ) => {
    const encodedImage: string | any = await getBase64(data);
    const compress = new Compressor(data, {
      quality: 0.7,
      convertSize: 100,
      convertTypes: ['image/png,image/jpg'],
      success: async (result: any) => {
        try {
          const cancelToken = axios.CancelToken;
          const source = cancelToken.source();
          if (field === 'prescription') {
            prescriptionProgress[index].cancelSource = source;
          } else {
            facesheetProgress[index].cancelSource = source;
          }
          const formData: any = new FormData();
          const compressedImage = new File([result], result.name, {
            type: result.type,
          });
          formData.append('file', compressedImage);
          formData.append('uuid', uuid);
          formData.append('userId', userId);
          const fileData = await uploadFile(
            formData,
            field === 'prescription'
              ? prescriptionProgress[index].cancelSource
              : facesheetProgress[index].cancelSource,
            (event: any) => {
              if (navigator.onLine) {
                if (Math.round((100 * event.loaded) / event.total) <= 99) {
                  if (field === 'prescription') {
                    prescriptionProgress[index].percentage =
                      prescriptionProgress[index].percentage <
                      Math.round((100 * event.loaded) / event.total)
                        ? Math.round((100 * event.loaded) / event.total)
                        : prescriptionProgress[index].percentage;
                  } else {
                    facesheetProgress[index].percentage =
                      facesheetProgress[index].percentage <
                      Math.round((100 * event.loaded) / event.total)
                        ? Math.round((100 * event.loaded) / event.total)
                        : facesheetProgress[index].percentage;
                  }
                }
              }
            }
          );

          if (fileData?.data) {
            if (field === 'prescription') {
              const newPrescriptionProgress = prescriptionProgress;
              newPrescriptionProgress[index] = {
                ...prescriptionProgress[index],
                encodedImg: encodedImage.split(',')[1],
                fileKey: fileData?.data,
                percentage: false,
                isError: false,
              };
              setPrescriptionProgress(newPrescriptionProgress);
            } else {
              const newFacesheetProgress = facesheetProgress;
              newFacesheetProgress[index] = {
                ...facesheetProgress[index],
                encodedImg: encodedImage.split(',')[1],
                fileKey: fileData?.data,
                percentage: false,
                isError: false,
              };
              setFacesheetProgress(newFacesheetProgress);
            }
          } else if (fileData?.error) {
            if (
              fileData?.error?.message !== 'timeout of 20000ms exceeded' &&
              fileData?.error?.message !== 'Network Error'
            ) {
              if (field === 'prescription') {
                prescriptionProgress[index].percentage = false;
                prescriptionProgress[index].isError = true;
              } else {
                facesheetProgress[index].percentage = false;
                facesheetProgress[index].isError = true;
              }
              notify(fileData?.error?.response?.data?.message);
            } else {
              if (field === 'prescription') {
                prescriptionProgress[index].percentage = false;
                prescriptionProgress[index].isError = true;
              } else {
                facesheetProgress[index].percentage = false;
                facesheetProgress[index].isError = true;
              }
              notify(t('NewLCOP-NetworkError'));
            }
          }
        } catch (error: any) {
          if (field === 'prescription') {
            prescriptionProgress[index].percentage = false;
            prescriptionProgress[index].isError = true;
          } else {
            facesheetProgress[index].percentage = false;
            facesheetProgress[index].isError = true;
          }
          notify(error?.response?.data?.message);
        }
        if (field === 'prescription') {
          formik.setFieldValue(`${field}Files`, prescriptionProgress);
          prescriptionProgress[index].percentage = false;
        } else {
          formik.setFieldValue(`${field}Files`, facesheetProgress);
          facesheetProgress[index].percentage = false;
        }
      },
      error: async () => {
        try {
          const cancelToken = axios.CancelToken;
          const source = cancelToken.source();
          if (field === 'prescription') {
            prescriptionProgress[index].cancelSource = source;
          } else {
            facesheetProgress[index].cancelSource = source;
          }
          const formData: any = new FormData();
          formData.append('file', data);
          formData.append('uuid', uuid);
          formData.append('userId', userId);
          const pdfData = await uploadFile(
            formData,
            field === 'prescription'
              ? prescriptionProgress[index].cancelSource
              : facesheetProgress[index].cancelSource,
            (event: any) => {
              if (navigator.onLine) {
                if (Math.round((100 * event.loaded) / event.total) <= 99) {
                  if (field === 'prescription') {
                    prescriptionProgress[index].percentage =
                      prescriptionProgress[index].percentage <
                      Math.round((100 * event.loaded) / event.total)
                        ? Math.round((100 * event.loaded) / event.total)
                        : prescriptionProgress[index].percentage;
                  } else {
                    facesheetProgress[index].percentage =
                      facesheetProgress[index].percentage <
                      Math.round((100 * event.loaded) / event.total)
                        ? Math.round((100 * event.loaded) / event.total)
                        : facesheetProgress[index].percentage;
                  }
                }
              }
            }
          );
          if (pdfData?.data) {
            if (field === 'prescription') {
              prescriptionProgress[index] = {
                ...prescriptionProgress[index],
                encodedImg: encodedImage.split(',')[1],
                fileKey: pdfData?.data,
              };
            } else {
              facesheetProgress[index] = {
                ...facesheetProgress[index],
                encodedImg: encodedImage.split(',')[1],
                fileKey: pdfData?.data,
              };
            }
          } else if (pdfData?.error) {
            if (
              pdfData?.error?.message !== 'timeout of 20000ms exceeded' &&
              pdfData?.error?.message !== 'Network Error'
            ) {
              if (field === 'prescription') {
                prescriptionProgress[index].isError = true;
                prescriptionProgress[index].percentage = false;
              } else {
                facesheetProgress[index].isError = true;
                facesheetProgress[index].percentage = false;
              }
              notify(pdfData?.error?.response?.data?.message);
            } else {
              if (field === 'prescription') {
                prescriptionProgress[index].isError = true;
                prescriptionProgress[index].percentage = false;
              } else {
                facesheetProgress[index].isError = true;
                facesheetProgress[index].percentage = false;
              }
              notify(t('NewLCOP-NetworkError'));
            }
          }
        } catch (error: any) {
          if (field === 'prescription') {
            prescriptionProgress[index].percentage = false;
            prescriptionProgress[index].isError = true;
          } else {
            facesheetProgress[index].percentage = false;
            facesheetProgress[index].isError = true;
          }
          notify(error?.response?.data?.message);
        }

        if (field === 'prescription') {
          formik.setFieldValue(`${field}Files`, prescriptionProgress);
          prescriptionProgress[index].percentage = false;
        } else {
          formik.setFieldValue(`${field}Files`, facesheetProgress);
          facesheetProgress[index].percentage = false;
        }
      },
    });
  };
  const handleTakePhotoChange = async (
    field: 'facesheet' | 'prescription',
    files: Array<{}>,
    cb: () => void
  ) => {
    if (!files || !Array.isArray(files) || files.length === 0) {
      // handle error
    }
    Object.values(files).map(async (data: File | Blob | any) => {
      const fileExtension = data?.name?.split('.').pop();
      const selectableMaxFileSize = 1024 * 1024 * 15;
      if (
        !attachmentFileTypes.includes(fileExtension) ||
        data.size > selectableMaxFileSize
      ) {
        if (!attachmentFileTypes.includes(fileExtension)) {
          notify(
            t(
              'InPatient-AddInPatientOrder-PrescriptionImageInvalidFileExtension'
            )
          );
        } else {
          notify('File size must be less than 15Mb');
        }
        formik.setFieldError(
          `${field}Files`,
          t('InPatient-AddInPatientOrder-PrescriptionImageInvalidFileExtension')
        );
        cb();
      } else if (field === 'prescription') {
        prescriptionProgress.push({
          percentage: 0,
          fileName: data?.name,
          index: prescriptionProgress.length,
          type: field,
          encodedImg: '',
          fileKey: '',
          isError: false,
          cancelSource: '',
        });
        fileUpload(prescriptionProgress.length - 1, field, data);
      } else {
        facesheetProgress.push({
          percentage: 0,
          fileName: data?.name,
          index: facesheetProgress.length,
          type: field,
          encodedImg: '',
          fileKey: '',
          isError: false,
          cancelSource: '',
        });
        fileUpload(facesheetProgress.length - 1, field, data);
      }
    });
    if (field === 'prescription') {
      formik.setFieldValue(`${field}Files`, prescriptionProgress);
    } else {
      formik.setFieldValue(`${field}Files`, facesheetProgress);
    }
    cb();
  };
  const LinearProgressWithLabel = (props: {
    value: any;
    index: number;
    type: 'facesheet' | 'prescription';
    cancelSource: CancelTokenSource;
  }) => {
    const progressProps = props;

    if (progressProps.value !== false) {
      return (
        <Box
          sx={{
            width: '70%',
            mr: 1,
            mt: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid sx={{ width: '90%' }}>
            <LinearProgress
              sx={{ height: 8, borderRadius: '5px' }}
              variant='determinate'
              {...progressProps}
            />
            <Box sx={{ minWidth: 35 }}>
              <Typography variant='body2' color='text.secondary'>
                {`${progressProps.value}%`}
              </Typography>
            </Box>
          </Grid>
          <Button
            sx={{
              display: 'flex',
              padding: '0 !important',
              justifyContent: 'flex-start',
            }}
            endIcon={<CloseIcon sx={{ marginBottom: '12px' }} />}
            onClick={() => {
              progressProps?.cancelSource?.cancel('Cancelled by user');
              if (progressProps.type === 'prescription') {
                prescriptionProgress[progressProps.index].percentage = false;
                prescriptionProgress[progressProps.index].isError = true;
              } else {
                facesheetProgress[progressProps.index].percentage = false;
                facesheetProgress[progressProps.index].isError = true;
              }
            }}
          />
        </Box>
      );
    }
    return null;
  };
  const handleRemoveAttachment = (
    field: 'facesheet' | 'prescription',
    index: number
  ) => {
    let allFiles: Array<{}> = [];
    if (field === 'facesheet') {
      allFiles = formik.values?.facesheetFiles;
    } else {
      allFiles = formik.values?.prescriptionFiles;
    }
    allFiles.splice(index, 1);
    formik.setFieldValue(`${field}Files`, allFiles);
  };

  const DeleteAttachment = async (fileKey: string) => {
    await deleteFile({ userId, fileKey });
  };
  const handleReset = () => {
    setIsCancelModalOpen(true);
  };
  const fieldHasError = (field: keyof CreateOrderModalRequest): boolean =>
    !!formik.touched[field] && Boolean(formik.errors[field]);

  const imagePreview = (
    index: number,
    file: FileProps,
    type: 'facesheet' | 'prescription'
  ) => (
    <>
      <DeleteOutlineIcon
        onClick={async () => {
          await DeleteAttachment(file?.fileKey);
          handleRemoveAttachment(type, index);
        }}
        sx={createOrderModalsx.deleteIcon}
      />
      <img
        src={`data:image/jpeg;base64,${file?.encodedImg}`}
        style={previewImageStyle}
        alt='prescription-preview'
      />
      <Tooltip title={file?.fileName}>
        <Typography variant='subtitle1' sx={createOrderModalsx.fileName}>
          {file?.fileName}
        </Typography>
      </Tooltip>
    </>
  );

  const pdfPreview = (
    index: number,
    file: FileProps,
    type: 'facesheet' | 'prescription'
  ) => (
    <>
      <DeleteOutlineIcon
        onClick={async () => {
          await DeleteAttachment(file?.fileKey);
          handleRemoveAttachment(type, index);
        }}
        sx={createOrderModalsx.deleteIcon}
      />
      <PictureAsPdfIcon sx={{ width: '100%', height: '100px' }} />
      <Tooltip title={file?.fileName}>
        <Typography variant='subtitle1' sx={createOrderModalsx.fileName}>
          {file?.fileName}
        </Typography>
      </Tooltip>
    </>
  );
  React.useEffect(() => {
    const timer = setInterval(() => {
      formik.setFieldValue(`facesheetFiles`, facesheetProgress);
      formik.setFieldValue(`prescriptionFiles`, prescriptionProgress);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [facesheetProgress, prescriptionProgress]);

  useEffect(() => {
    setUuid(uuidv4());
  }, []);
  // focus first input default
  useEffect(() => {
    const language: string | any = secureLocalStorage.getItem('language');
    changeI18nLanguage(language);
    document.title = t('NewLC-BrowserTab-LactationConsultant');
    if (isCreateOrderModalOpen && focusInput) {
      setTimeout(() => {
        focusInput.current?.focus();
      }, 500);
    }
  }, [isCreateOrderModalOpen]);
  const nameRegex = /^[a-zA-Z-]+$/;

  return (
    <>
      <Modal
        hideBackdrop
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: ' 30px 0',
          overflowY: 'auto',
          '.input-error': {
            border: '1px solid #d32f2f!important',
          },
        }}
        open={isCreateOrderModalOpen}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            borderRadius: 4,
            position: 'relative',
            width: 800,
            maxWidth: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            overflowY: 'auto',
            margin: 'auto',
            outline: 'none',
          }}
        >
          <Box mb={3}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              {t('Orders-Index-NewItemPrompt')}
            </Typography>
            <Button
              sx={{
                position: 'absolute',
                right: '10px',
                top: '20px',
                color: '#000',
              }}
              data-testid='close-order-modal'
              endIcon={<CloseIcon />}
              onClick={handleReset}
            />
          </Box>
          <Container maxWidth='sm'>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item sm={6} xs={12}>
                  <InputLabel sx={createOrderModalsx.inputLabel}>
                    {t('InPatient-AddInPatientOrder-FirstNameField')}
                  </InputLabel>
                  <TextField
                    onKeyDown={(event) => {
                      if (!nameRegex.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder={t(
                      'InPatient-AddInPatientOrder-FirstNameField'
                    )}
                    {...textFieldProps}
                    InputProps={{
                      sx: createOrderModalsx.input,
                      inputRef: focusInput,
                    }}
                    {...formik.getFieldProps('firstName')}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formik.handleChange({
                        target: {
                          value: e.target.value,
                          name: 'firstName',
                        },
                      })
                    }
                    error={fieldHasError('firstName')}
                    helperText={getFieldHelperText('firstName')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel sx={createOrderModalsx.inputLabel}>
                    {t('InPatient-AddInPatientOrder-LastNameField')}
                  </InputLabel>
                  <TextField
                    onKeyDown={(event) => {
                      if (!nameRegex.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder={t('InPatient-AddInPatientOrder-LastNameField')}
                    {...textFieldProps}
                    InputProps={{
                      sx: createOrderModalsx.input,
                    }}
                    {...formik.getFieldProps('lastName')}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formik.handleChange({
                        target: {
                          value: e.target.value,
                          name: 'lastName',
                        },
                      })
                    }
                    error={fieldHasError('lastName')}
                    helperText={getFieldHelperText('lastName')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel sx={createOrderModalsx.inputLabel}>
                    {t('PatientInsurance-Edit-DateofBirthPrompt')}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      mask='__/__/____'
                      disableFuture
                      inputFormat='MM/dd/yyyy'
                      value={formik.values.dateOfBirth}
                      onChange={(e: any) =>
                        formik.handleChange({
                          target: {
                            value: e,
                            name: 'dateOfBirth',
                          },
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          sx={{
                            '.MuiInputBase-formControl': {
                              color: '#899dad',
                              fontSize: 13,
                              backgroundColor: '#F5F6FA',
                              height: 40,
                              margin: 0,
                              maxWidth: '100%',
                            },
                            fieldset: {
                              border: '1px solid #899dad ',
                            },
                          }}
                          error={
                            formik.errors.dateOfBirth ===
                              t('DateOfBirthNull') ||
                            fieldHasError('dateOfBirth')
                          }
                          helperText={
                            formik.errors.dateOfBirth === t('DateOfBirthNull')
                              ? formik.errors.dateOfBirth
                              : getFieldHelperText('dateOfBirth')
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel sx={createOrderModalsx.inputLabel}>
                    {t('Facilities-Add-PhoneNumberPrompt')}
                  </InputLabel>
                  <TextField
                    placeholder='(xxx) xxx-xxxx'
                    {...textFieldProps}
                    InputProps={{
                      sx: createOrderModalsx.input,
                    }}
                    inputProps={{ inputMode: 'numeric' }}
                    {...formik.getFieldProps('phoneNumber')}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const val: string | any = e.target.value
                        ?.replace(/\D/g, '')
                        ?.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                      e.target.value = !val[2]
                        ? val[1]
                        : `(${val[1]}) ${val[2]}${val[3] ? `-${val[3]}` : ''}`;
                      formik.handleChange({
                        target: {
                          value: e.target.value,
                          name: 'phoneNumber',
                        },
                      });
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formik.handleChange({
                        target: {
                          value: e.target.value,
                          name: 'phoneNumber',
                        },
                      })
                    }
                    error={fieldHasError('phoneNumber')}
                    helperText={getFieldHelperText('phoneNumber')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel sx={createOrderModalsx.inputLabel}>
                    {t('LCLogons-Add-PreferredCulturePrompt')}
                  </InputLabel>
                  <Autocomplete
                    onChange={(e, newValue) => {
                      formik.setFieldValue(`language`, newValue);
                    }}
                    disableClearable
                    onInputChange={() => formik.setFieldValue(`language`, '')}
                    value={formik.values.language}
                    options={supportedLanguages.map((lang) => lang)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t(
                          'InPatient-AddInPatientOrder-PlaceholderForLanguage'
                        )}
                        {...formik.getFieldProps('language')}
                        error={fieldHasError('language')}
                        helperText={getFieldHelperText('language')}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            color: '#899dad',
                            fontSize: '13px',
                            backgroundColor: '#F5F6FA',
                            fontWeight: '400 ',
                            height: '40px ',
                            margin: '0',
                            width: '100%',
                            maxWidth: '100%',
                            '.MuiAutocomplete-input': {
                              p: '1.5px 4px 2.5px 4px !important',
                            },
                          },
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item sm={6} xs={12}>
                  <>
                    <InputLabel sx={createOrderModalsx.inputLabel}>
                      {t('InPatient-AddInPatientOrder-AttachFaceSheetButton')}
                    </InputLabel>
                    <TakePhoto
                      name='facesheetEncodedImg'
                      ariaLabelledby='facesheet-Attachments'
                      onChange={(data: any, cb: any) =>
                        handleTakePhotoChange(
                          'facesheet',
                          data.target.files,
                          cb
                        )
                      }
                    />
                    {fieldHasError('facesheetFiles') && (
                      <FormHelperText error>
                        {formik.errors?.facesheetFiles}
                      </FormHelperText>
                    )}
                    {formik?.values?.facesheetFiles &&
                      formik?.values?.facesheetFiles.map(
                        (file: any, index: number) => (
                          <>
                            <Grid key={`file${file.fileName}`}>
                              <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel
                                  value={file?.percentage}
                                  index={file?.index}
                                  type='facesheet'
                                  cancelSource={file?.cancelSource}
                                />
                              </Box>
                            </Grid>
                            {file.percentage === false &&
                              file.isError !== true && (
                                <Box
                                  sx={createOrderModalsx.attachementPreview}
                                  key={`file${file}`}
                                >
                                  {file.fileName?.split('.').pop() === 'pdf'
                                    ? pdfPreview(index, file, 'facesheet')
                                    : imagePreview(index, file, 'facesheet')}
                                </Box>
                              )}
                          </>
                        )
                      )}
                  </>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <>
                    <InputLabel sx={createOrderModalsx.inputLabel}>
                      {t('InPatient-AddInPatientOrder-AttachPrescriptionBtn')}
                    </InputLabel>
                    <TakePhoto
                      name='prescription'
                      ariaLabelledby='prescription-attachment'
                      onChange={(data: any, cb: any) =>
                        handleTakePhotoChange(
                          'prescription',
                          data.target.files,
                          cb
                        )
                      }
                    />
                    {fieldHasError('prescriptionFiles') && (
                      <FormHelperText error>
                        {formik.errors.prescriptionFiles}
                      </FormHelperText>
                    )}
                    {formik?.values?.prescriptionFiles &&
                      formik?.values?.prescriptionFiles.map(
                        (file: any, index: number) => (
                          <>
                            <Grid key={`file${file.fileName}`}>
                              <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel
                                  value={file?.percentage}
                                  index={file?.index}
                                  type='prescription'
                                  cancelSource={file?.cancelSource}
                                />
                              </Box>
                            </Grid>
                            {file?.percentage === false &&
                              file?.isError !== true && (
                                <Box
                                  sx={createOrderModalsx.attachementPreview}
                                  key={`file${file}`}
                                >
                                  {file.fileName?.split('.').pop() === 'pdf'
                                    ? pdfPreview(index, file, 'prescription')
                                    : imagePreview(index, file, 'prescription')}
                                </Box>
                              )}
                          </>
                        )
                      )}
                  </>
                </Grid>
              </Grid>
              {/* Buttom section */}
              <Stack
                mt={5}
                direction='row'
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  '.Mui-disabled': {
                    background: '#ef9047bd!important',
                    color: '#fff !important',
                  },
                }}
              >
                <Button
                  variant='contained'
                  sx={{
                    background: '#E7EEF7 !important',
                    color: '#4A6B89',
                    marginRight: '15px',
                    textTransform: 'capitalize',
                    boxShadow: 'none !important',
                    fontSize: '13px',
                    fontWeight: '700 !important',
                  }}
                  onClick={handleReset}
                >
                  {t('InPatient-ProvidePump-CancelButton')}
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    background: '#E6863E !important',
                    color: '#fff',
                    textTransform: 'capitalize',
                    boxShadow: 'none !important',
                    fontSize: '13px',
                    fontWeight: '700 !important',
                  }}
                  disabled={
                    !formik.isValid ||
                    !formik.dirty ||
                    loading ||
                    prescriptionProgress
                      .filter((opt: FileProps) => opt.isError === false)
                      .filter((opt: FileProps) => opt.percentage === false)
                      .length === 0 ||
                    facesheetProgress
                      .filter((opt: FileProps) => opt.isError === false)
                      .filter((opt: FileProps) => opt.percentage === false)
                      .length === 0 ||
                    prescriptionProgress.filter(
                      (opt: FileProps) => opt.percentage !== false
                    ).length !== 0 ||
                    facesheetProgress.filter(
                      (opt: FileProps) => opt.percentage !== false
                    ).length !== 0
                  }
                  type='submit'
                >
                  {t('SubmitPrompt')}
                </Button>
              </Stack>
            </form>
          </Container>
        </Box>
      </Modal>
      {loading && (
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: ' 30px 0',
            overflowY: 'auto',
            '.input-error': {
              border: '1px solid #d32f2f!important',
            },
          }}
          open={loading}
          onClose={onClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={{
              borderRadius: 4,
              position: 'relative',
              width: 800,
              maxWidth: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 3,
              overflowY: 'auto',
              margin: 'auto',
              outline: 'none',
            }}
          >
            <CircularProgress sx={{ color: '#E6863E' }} />
          </Box>
        </Modal>
      )}
      <Dialog
        open={isCancelModalOpen}
        sx={{
          borderRadius: '8px !important',
          border: 'none',
        }}
      >
        <DialogContent sx={{ textAlign: 'center' }}>
          <DialogContentText sx={{ color: 'black' }}>
            {t('InPatient-AddInPatientOrder-ConfirmCancelPrompt')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '15px !important' }}>
          <Button
            sx={{
              background: '#E6863E !important',
              color: '#fff',
              textTransform: 'capitalize',
              boxShadow: 'none !important',
              fontSize: '15px',
              fontWeight: '700 !important',
              px: 2,
              borderRadius: 2,
              marginRight: '10px',
            }}
            autoFocus
            onClick={() => handleCloseCancelModal(true)}
          >
            {t('OkPrompt')}
          </Button>
          <Button
            sx={{
              background: '#E7EEF7 !important',
              color: '#4A6B89',

              textTransform: 'capitalize',
              boxShadow: 'none !important',
              fontSize: '15px',
              fontWeight: '700 !important',
            }}
            onClick={() => handleCloseCancelModal(false)}
          >
            {t('CancelPrompt')}
          </Button>
        </DialogActions>
      </Dialog>
      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
