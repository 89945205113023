import Axios from 'axios';
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import { apiUrl, apiVersion } from '../utils/Constants';
import { InventoryModel } from '../models/Inventory';
import 'react-toastify/dist/ReactToastify.css';

const cookies = new Cookies();
export const inventoryEndpoint = `${apiUrl}/api/v${apiVersion}/inventoryItems`;

const notify = (message: string) => {
  toast.error(message, { position: 'top-center' });
};
export const getAllInventory = async (facilityId: number, t: Function) => {
  try {
    const { data } = await Axios.get<InventoryModel[]>(
      `${inventoryEndpoint}/facility/${facilityId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.get('accessToken')}`,
        },
        timeout: 1000 * 20,
      }
    );
    return data.length !== 0 ? data : [];
  } catch (error: any) {
    if (
      error?.message === 'timeout of 20000ms exceeded' ||
      error?.message === 'Network Error'
    ) {
      notify(t('NewLCOP-NetworkError'));
    } else {
      notify(error?.response?.data?.message);
    }
    return [];
  }
};

export const inventoryMoveToShelf = (
  facilityId: number,
  serialNumber: string,
  userId: string
) => {
  const data = new FormData();
  data.append('serialNumber', serialNumber);
  data.append('userId', userId);
  return Axios.post(
    `${inventoryEndpoint}/facility/${facilityId}/moveToShelf`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('accessToken')}`,
      },
    }
  );
};

export const inventoryMoveToShelfs = (
  facilityId: number,
  serialNumber: string,
  userId: string
) =>
  Axios.post(
    `${inventoryEndpoint}/facility/${facilityId}/moveToShelfs?serialNumber=${serialNumber}&userId=${userId}`,
    [],
    {
      headers: {
        Authorization: `Bearer ${cookies.get('accessToken')}`,
      },
    }
  );

export const validateSerialNumber = async (serialNumber: any) => {
  const { data } = await Axios.get(
    `${inventoryEndpoint}/validate/${serialNumber}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('accessToken')}`,
      },
    }
  );
  return data;
};
