import { Cookies } from 'react-cookie';
import { FunctionComponent, ReactNode } from 'react';
import { SessionExpireModal } from '../../../components/SessionExpireModal/SessionExpireModal';

interface AppLayoutProps {
  children: ReactNode;
}

const cookies = new Cookies();
export const PrivateRoute: FunctionComponent<AppLayoutProps | any> = ({
  children,
}) => {
  if (
    cookies.get('accessToken') !== undefined &&
    cookies.get('accessToken') !== null
  ) {
    return children;
  }
  return (
    <SessionExpireModal
      navigateTo='userLogin'
      isSessionExpire={!cookies.get('accessToken')}
    />
  );
};
