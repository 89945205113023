import { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

interface CreateOrderModalProps {
  isSessionExpire: boolean;
  navigateTo: string;
}

export const SessionExpireModal: FunctionComponent<CreateOrderModalProps> = ({
  isSessionExpire,
  navigateTo,
}) => {
  const navigate = useNavigate();
  const userId = secureLocalStorage.getItem('userId');
  const userName = secureLocalStorage.getItem('displayName');
  const facilityId = secureLocalStorage.getItem('facilityId');
  const hospitalName = secureLocalStorage.getItem('hospitalName');
  const handleSessionModalClose = () => {
    if (navigateTo === 'userList') {
      navigate(`/users?facilityId=${facilityId}`);
      window.location.reload();
    } else {
      navigate(`/userLogin`, {
        state: { userId, userName, facilityId, hospitalName },
      });
      window.location.reload();
    }
  };
  return (
    <Dialog
      open={isSessionExpire}
      sx={{ borderRadius: '8px !important', border: 'none' }}
    >
      <DialogContent>
        <DialogContentText sx={{ color: 'black' }}>
          Your session has expired. Please log back in.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            background: '#E6863E !important',
            color: '#fff',
            textTransform: 'capitalize',
            boxShadow: 'none !important',
            fontSize: '15px',
            fontWeight: '700 !important',
            px: 2,
            borderRadius: 2,
          }}
          autoFocus
          onClick={handleSessionModalClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpireModal;
