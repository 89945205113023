import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { FunctionComponent, useState, useRef, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { object, string } from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import image from './image.jpg';
import logo from '../../assets/images/logo-bpd.png';
import userIcon from '../../assets/images/usericon.png';
import { validatePin } from '../../services/UserService';
import 'react-toastify/dist/ReactToastify.css';
import { NewUpdateVersionModal } from '../../components/NewUpdateVersionModal/NewUpdateVersionModal';
import { badge } from '../../common/utils/Theme';

const notify = (message: any) => {
  toast.error(message, { position: 'top-center' });
};
interface Pin {
  pin: '';
}

export const UserLogin: FunctionComponent = () => {
  const { t } = useTranslation();
  const [pin, setPin] = useState<string>('');
  const [cookies, setCookie] = useCookies(['accessToken']);
  const [session, setSession] = useState<string>('');
  const { serviceWorkerUpdated } = useSelector(
    (state: any) => state.ServiceWorkerReducer
  );
  const pinInput = useRef<null | HTMLInputElement>(null);
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const validationSchema = object({
    pin: string()
      .required(t('InPatient-LCLogin-PinError'))
      .min(6, t('InvalidNewPassword'))
      .matches(/\d+/, t('PasswordMissingDigit')),
  });
  useEffect(() => {
    setSession(uuidv4());
    localStorage.removeItem('isNotifyLcLoginModalOpen');
  }, []);
  const handleValidatePinData = async () => {
    try {
      const formData = new FormData();
      formData.append('pin', pin);
      const data = await validatePin(state?.userId, formData);
      localStorage.setItem('session', session);
      setCookie('accessToken', data.accessToken, {
        path: '/',
        expires: moment()
          .add(data.expiresIn - 1, 'm')
          .toDate(),
      });
      navigate('/orders', {
        state: {
          session,
          facilityId: state?.facilityId,
          userId: state?.userId,
        },
      });
    } catch (error: any) {
      notify(t('InvalidNewPassword'));
      // TODO handle error
    }
  };
  const formik = useFormik({
    initialValues: { pin: '' },
    validationSchema,
    onSubmit: async () => {
      handleValidatePinData();
    },
  });

  useEffect(() => {
    if (!secureLocalStorage.getItem('userId') || !state?.userId) {
      navigate(
        `/users?facilityId=${
          state?.facilityId
            ? state?.facilityId
            : secureLocalStorage.getItem('facilityId')
        }`
      );
    }
    if (pinInput && typeof pinInput.current?.focus === 'function') {
      pinInput.current?.focus();
    }
  }, []);

  const getFieldHelperText = (field: keyof Pin): string | undefined =>
    formik.touched[field] ? formik.errors[field] : undefined;

  const fieldHasError = (field: keyof Pin): boolean =>
    !!formik.touched[field] && Boolean(formik.errors[field]);

  const handleBack = () => {
    navigate(
      `/users?facilityId=${
        state?.facilityId
          ? state?.facilityId
          : secureLocalStorage.getItem('facilityId')
      }`
    );
  };

  return (
    <>
      <Box
        sx={
          process.env.REACT_APP_CUR_ENV === 'DEV' ||
          process.env.REACT_APP_CUR_ENV === 'UAT'
            ? badge
            : null
        }
      >
        <Box
          className='badge'
          sx={{
            width: '100%',
            display: 'block',
            m: 'auto',
            textAlign: 'center',
            overflow: 'hidden',
            pl: 0,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <ToastContainer />
            <Grid
              container
              columnSpacing={2}
              sx={{ ml: -1, minHeight: { xs: 400, sm: '100vh' } }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  width: '-webkit-fill-available',
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  display: { xs: 'none', sm: 'block' },
                }}
              />

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  p: { xs: 0, sm: '24px !important' },
                  m: 0,
                  backgroundColor: 'white',
                  pl: { xs: '0px !important' },
                }}
              >
                <Grid sx={{ mt: 4 }}>
                  <img
                    src={logo}
                    style={{ width: ' 100%', maxWidth: '400px' }}
                  />
                  <Typography
                    variant='h5'
                    sx={{ mt: 3, mb: 2, color: '#4a6b89' }}
                  >
                    {t('OutPatient-Landing-Title')} to {state?.hospitalName}
                  </Typography>
                </Grid>
                <Grid container sx={{ justifyContent: { xs: 'space-evenly' } }}>
                  <Grid
                    item
                    sm={3}
                    sx={{
                      p: 0.6,
                      textDecoration: 'none',
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: 4,
                        backgroundColor: '#eef2f5',
                        boxShadow: 'none',
                      }}
                    >
                      <CardMedia sx={{ mt: 2 }}>
                        <img src={userIcon} style={{ width: '20%' }} />
                      </CardMedia>
                      <CardContent sx={{ pb: 2, p: 0.6 }}>
                        {state?.userName
                          ? state?.userName
                          : secureLocalStorage.getItem('userName')}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Typography component='h1' variant='h5'>
                    {t('InPatient-LCLogin-LCLoginSelectPinPrompt')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: '100%',
                  }}
                >
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl sx={{ mr: 1, minWidth: 300, border: 0 }}>
                      <TextField
                        id='pin'
                        autoFocus
                        InputProps={{
                          inputProps: { min: 0, autoCapitalize: 'none' },
                          inputRef: pinInput,
                        }}
                        {...formik.getFieldProps('pin')}
                        error={fieldHasError('pin')}
                        helperText={getFieldHelperText('pin')}
                        onChange={(e: any) => [
                          formik.handleChange({
                            target: {
                              value: e.target.value,
                              name: 'pin',
                            },
                          }),
                          setPin(e.target.value),
                        ]}
                        placeholder={t(
                          'InPatient-LCLogin-LCLoginSelectPinPrompt'
                        )}
                        fullWidth
                        size='small'
                        sx={{
                          color: '#899dad',
                          fontSize: '13px',
                          backgroundColor: '#F5F6FA',
                          fontWeight: '400 ',
                          height: '40px ',
                          m: 0,
                          '#pin': {
                            '-webkit-text-security': 'disc',
                            '-moz-text-security': 'circle',
                            textSecurity: 'circle',
                          },
                          '& input[type=number]': {
                            '-moz-appearance': 'textfield',
                          },
                          '& input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                          '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                <Button
                  type='submit'
                  variant='contained'
                  sx={{
                    backgroundColor: '#e6863e',
                    margin: '50px auto 30px',
                    display: 'block',
                    boxShadow: 'none',
                    borderRadius: '5px',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    padding: '8px 25px',
                    fontWeight: '600',
                    mt: 4,
                    mb: 3,
                    '&:hover': {
                      background: '#ef9047',
                    },
                  }}
                >
                  {t('InPatient-LCLogin-SignInPrompt')}
                </Button>
                <Button
                  onClick={handleBack}
                  type='submit'
                  variant='contained'
                  sx={{
                    backgroundColor: '#f5f6fa',
                    margin: '50px auto 30px',
                    display: 'flex',
                    boxShadow: 'none',
                    borderRadius: '50%',
                    fontSize: '24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50px',
                    minWidth: '50px',
                    color: '#000',
                    padding: '0',
                    '&:hover': {
                      background: '#eef2f5',
                    },
                  }}
                >
                  <ChevronLeftIcon />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
