import { SW_INIT, SW_UPDATE } from '../../../../utils/Constants';

const initialState: any = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};
const ServiceWorkerReducer = (
  state = initialState,
  { type, payload }: any = {}
) => {
  switch (type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: payload,
      };
    default:
      return state;
  }
};

export default ServiceWorkerReducer;
