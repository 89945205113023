import { Container, CssBaseline, ThemeProvider, Box } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { NewUpdateVersionModal } from '../../../components/NewUpdateVersionModal/NewUpdateVersionModal';
import { badge, theme } from '../../utils/Theme';

interface AppLayoutProps {
  children?: ReactNode;
}

export const AppLayout: FunctionComponent<AppLayoutProps> = ({ children }) => {
  const { serviceWorkerUpdated } = useSelector(
    (state: any) => state.ServiceWorkerReducer
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <CssBaseline />
        <Box
          sx={
            process.env.REACT_APP_CUR_ENV === 'DEV' ||
            process.env.REACT_APP_CUR_ENV === 'UAT'
              ? badge
              : null
          }
        >
          <Container className='badge' component='main' maxWidth='xl'>
            {children}
          </Container>
        </Box>
      </ThemeProvider>
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
