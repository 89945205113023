import OrdersReducer from './OrdersReducer';
import InventoryReducer from './InventoryReducer';
import ShelfReducer from './ShelfReducer';
import ServiceWorkerReducer from './ServiceWorkerReducer';

const rootReducer = {
  OrdersReducer,
  InventoryReducer,
  ShelfReducer,
  ServiceWorkerReducer,
};

export default rootReducer;
