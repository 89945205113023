/* eslint-disable no-await-in-loop */
import Axios, { CancelTokenSource } from 'axios';
import { Cookies } from 'react-cookie';
import { CreateOrderRequest } from '../models/CreateOrderRequest';
import { CreateOrderResponse } from '../models/CreateOrderResponse';
import { apiUrl, apiVersion } from '../utils/Constants';
import { Order } from '../models/Order';
import { Product } from '../models/Product';

const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
const cookies = new Cookies();
export const ordersEndpoint = `${apiUrl}/api/v${apiVersion}/orders`;
interface FileProps {
  uuid: string;
  userId: number;
  file: File;
}
const createOrderApi = async (request: CreateOrderRequest) => {
  let orderData;
  try {
    const { data } = await Axios.post<CreateOrderResponse>(
      `${ordersEndpoint}/inPatient`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.get('accessToken')}`,
        },
      }
    );
    orderData = { data };
    return orderData;
  } catch (error: any) {
    orderData = { error };
    return orderData;
  }
};
export const createOrder = async (request: CreateOrderRequest) => {
  let orderData;
  for (let retry = 0; retry < 4; retry += 1) {
    try {
      const data = await createOrderApi(request);
      if (
        data?.error?.message !== 'timeout of 20000ms exceeded' &&
        data?.error?.message !== 'Network Error'
      ) {
        if (data?.error) {
          orderData = { error: data?.error };
        } else {
          orderData = { data: data?.data };
        }

        break;
      } else {
        orderData = { error: data?.error };
      }
    } catch (error: any) {
      orderData = { error };
      return error;
    }
    await delay(500);
  }
  return orderData;
};

export const getAllOrdersApi = async (facilityId: number) => {
  let orderData;
  try {
    const { data } = await Axios.get<Order[]>(
      `${ordersEndpoint}/facility/${facilityId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.get('accessToken')}`,
        },
        timeout: 1000 * 20,
      }
    );
    orderData = { data: data.length !== 0 ? data : [] };
    return orderData;
  } catch (error: any) {
    orderData = { error, data: [] };
    return orderData;
  }
};
export const getAllOrders = async (facilityId: number) => {
  let data;
  for (let retry = 0; retry < 4; retry += 1) {
    try {
      data = await getAllOrdersApi(facilityId);
      if (
        data?.error?.message !== 'timeout of 20000ms exceeded' &&
        data?.error?.message !== 'Network Error'
      ) {
        break;
      }
    } catch (error: any) {
      data = { error, data: [] };
      return data;
    }
    await delay(500);
  }
  return data;
};

export const assignPump = async (request: Product, orderId: string) => {
  const { data } = await Axios.put(
    `${ordersEndpoint}/inPatient/${orderId}`,
    request,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('accessToken')}`,
      },
    }
  );

  return data;
};
export const validatePump = async (request: any) => {
  const { data } = await Axios.post(
    `${ordersEndpoint}/inPatient/validatePump`,
    request,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('accessToken')}`,
      },
    }
  );

  return data;
};
export const uploadFileApi = async (
  request: FileProps,
  cancelSource: CancelTokenSource,
  onUploadProgress: (event: React.ChangeEvent<Body>) => void
) => {
  let fileData;
  try {
    const { data } = await Axios.post(
      `${ordersEndpoint}/inPatient/uploadFile`,
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.get('accessToken')}`,
        },
        cancelToken: cancelSource?.token,
        onUploadProgress,
      }
    );
    fileData = { data };
    return fileData;
  } catch (error: any) {
    fileData = { error };
    return fileData;
  }
};
export const uploadFile = async (
  request: FileProps,
  cancelSource: CancelTokenSource,
  onUploadProgress: (event: React.ChangeEvent<Body>) => void
) => {
  let fileData;
  for (let retry = 0; retry < 4; retry += 1) {
    try {
      const data = await uploadFileApi(request, cancelSource, onUploadProgress);
      if (
        data?.error?.message !== 'timeout of 20000ms exceeded' &&
        data?.error?.message !== 'Network Error'
      ) {
        if (data?.error) {
          fileData = { error: data?.error };
        } else {
          fileData = { data: data?.data };
        }
        break;
      } else {
        fileData = { error: data?.error };
      }
    } catch (error: any) {
      fileData = { error };
      return error;
    }
    await delay(500);
  }

  return fileData;
};
export const deleteFile = async (request: any) => {
  const { data } = await Axios.post(
    `${ordersEndpoint}/inPatient/deleteFile`,
    request,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('accessToken')}`,
      },
    }
  );

  return data;
};

export const getNotificationMessage = async () => {
  const { data } = await Axios.get(
    `${apiUrl}/api/v${apiVersion}/setting?keyName=AllFacilitiesBroadcastEnabled`,
    {
      headers: {
        Authorization: `Bearer ${cookies.get('accessToken')}`,
      },
    }
  );
  return data;
};
