import { FunctionComponent, useState, useRef, useEffect } from 'react';
import {
  Button,
  Grid,
  Box,
  Typography,
  Modal,
  TextField,
  InputLabel,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { date, object, string } from 'yup';
import { useFormik } from 'formik';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Cookies } from 'react-cookie';
import moment from 'moment';
import { Product } from '../../models/Product';
import { SignatureModal } from '../SignatureModal/SignatureModal';
import { BarcodeScannerModal } from '../QRCodeScanner/QRCodeScanner';
import { validatePump } from '../../services/OrderService';
import 'react-toastify/dist/ReactToastify.css';
import { SessionExpireModal } from '../SessionExpireModal/SessionExpireModal';
import { changeI18nLanguage } from '../../common/utils/Utils';
import { NewUpdateVersionModal } from '../NewUpdateVersionModal/NewUpdateVersionModal';

interface AssignProductModalProps {
  isProductModalOpen: boolean;
  handleAssignProductModalClose: () => void;
  onProductDataChange: Function;
  productModalData: Product;
  getOrders: () => void;
  birthDate: string;
}
const createOrderModalsx = {
  inputLabel: {
    color: '#4A6B89',
    fontSize: 15,
    margin: '15px 0 5px ',
  },
  textField: {
    border: '0',
    margin: '0',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#899dad',
      },
      '&:hover fieldset': {
        borderColor: '#899dad',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#899dad ',
      },
    },
  },
  input: {
    color: '#899dad',
    fontSize: 13,
    backgroundColor: '#F5F6FA',
    fontWeight: 400,
    height: 40,
    margin: '0',
  },
  button: {
    background: '#4A6B89',
    color: '#fff',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  selectFiled: {
    '& .MuiOutlinedInput-notchedOutline': {
      '& ': {
        borderColor: '#899dad !important',
      },
      '&:hover': {
        borderColor: '#899dad!important',
      },
      '&.Mui-focused': {
        borderColor: '#899dad!important',
      },
    },
  },
  selectinput: {
    color: '#899dad ',
    fontSize: 13,
    backgroundColor: '#F5F6FA !important',
    fontWeight: 400,
    margin: 0,
    '&:focus': {
      backgroundColor: '#ffddec',
      borderColor: 'brown',
    },
    '&:before': {
      borderColor: 'orange',
    },
    '&:after': {
      borderColor: 'green',
    },
  },
  bottomButtons: {
    boxShadow: 'none',
    fontSize: 13,
    fontWeight: 700,
  },
};

const notify = (message: any) => {
  toast.error(message, { position: 'top-center' });
};
export const AssignProductModal: FunctionComponent<AssignProductModalProps> = ({
  isProductModalOpen,
  handleAssignProductModalClose,
  onProductDataChange,
  productModalData,
  getOrders,
  birthDate,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { state }: any = useLocation();
  const [isSignatureModalOpen, setIsSignatureModalOpen] =
    useState<boolean>(false);
  const { serviceWorkerUpdated } = useSelector(
    (serviceWorkerState: any) => serviceWorkerState.ServiceWorkerReducer
  );
  const focusInput = useRef<null | HTMLInputElement>(null);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState<boolean>(false);
  const handleQRModalClose = () => {
    setIsQRCodeModalOpen(false);
  };
  const handleOpenSignatureModal = () => {
    if (
      productModalData.serialNumber !== '' &&
      productModalData.dateOfBirth !== ''
    ) {
      setIsSignatureModalOpen(true);
      onProductDataChange({
        ...productModalData,
        dateOfBirth: moment(productModalData.dateOfBirth).format('yyyy-MM-DD'),
      });
    }
  };
  const validationSchema = object({
    serialNumber: string()
      .required(t('NewLC-AssignPump-SerialRequired'))
      .trim(t('NewLC-AssignPump-SerialNoSpaces')),
    dateOfBirth: date()
      .typeError(t('DateOfBirthNull'))
      .required(t('InPatient-AddInPatientOrder-DateOfBirthError'))
      .min(
        birthDate ? new Date(birthDate) : new Date(),
        t('InPatient-ProvidePump-DateOfBirthError')
      )
      .max(
        birthDate ? new Date(birthDate) : new Date(),
        t('InPatient-ProvidePump-DateOfBirthError')
      ),
  });

  const formik = useFormik({
    initialValues: new Product(),
    validationSchema,
    onSubmit: async () => {
      try {
        await validatePump({
          userId: productModalData.userId,
          serialNumber: formik.values.serialNumber,
        });
        handleOpenSignatureModal();
      } catch (error: any) {
        if (error.response.data.error === 'Unauthorized') {
          navigate(
            `/users?facilityId=${secureLocalStorage.getItem('facilityId')}`
          );
        } else if (
          error.response.data.message.localeCompare(
            'The serial number is not registered to this Depot.'
          ) === 0
        ) {
          notify(t('NewLC-AssignPump-SerialNotRegistered'));
        } else if (
          error.response.data.message.localeCompare(
            'The serial number you entered does not exist.'
          ) === 0
        ) {
          notify(t('NewLC-AssignPump-SerialNotexist'));
        } else {
          notify(error?.response?.data?.message);
        }
        setIsSignatureModalOpen(false);
      }
    },
  });

  const getFieldHelperText = (field: keyof Product): string | undefined =>
    formik.touched[field] ? formik.errors[field] : undefined;

  const fieldHasError = (field: keyof Product): boolean =>
    !!formik.touched[field] && Boolean(formik.errors[field]);

  const handleReset = (e: React.HTMLInputTypeAttribute) => {
    handleAssignProductModalClose();
    formik.handleReset(e);
  };
  const handleCloseSignatureModal = () => {
    setIsSignatureModalOpen(false);
  };
  const handleSerialNumberChange = (e: string) => {
    onProductDataChange({
      ...productModalData,
      serialNumber: e,
    });
    formik.handleChange({
      target: {
        value: e,
        name: 'serialNumber',
      },
    });
  };
  useEffect(() => {
    if (isProductModalOpen && focusInput) {
      setTimeout(() => {
        focusInput.current?.focus();
      }, 500);
    }
  }, [isProductModalOpen]);
  useEffect(() => {
    const language: string | any = secureLocalStorage.getItem('language');
    changeI18nLanguage(language);
  }, []);

  return (
    <>
      <Modal
        hideBackdrop
        sx={{ overflowY: 'scroll', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        open={isProductModalOpen}
        onClose={handleAssignProductModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'relative',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            maxWidth: '90%',
            bgcolor: 'background.paper',
            p: { xs: 0, sm: 3 },
            outline: 'none',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid
              sx={{
                display: 'flex',
                flexShrink: 0,
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '1rem 1rem',
                border: 'none',
                borderTopLeftRadius: 'calc(0.3rem - 1px)',
                borderTopRightRadius: 'calc(0.3rem - 1px)',
              }}
            >
              <Grid
                sx={{
                  fontSize: 'x-large',
                  margin: 1,
                  display: 'flex',
                  fontWeight: 500,
                  color: '#212529',
                }}
              >
                {t('InPatient-ProvidePump-AssignTitle')}
              </Grid>
              <Button
                type='button'
                className='close'
                sx={{
                  fontSize: 'x-large',
                  fontWeight: 600,
                  color: '#212529',
                  border: 'none',
                  backgroundColor: 'white',
                  margin: { xs: '-2.5rem 0rem -1rem auto' },
                }}
                data-dismiss='modal'
                aria-label='Close'
                onClick={(e: any) => handleReset(e)}
              >
                <Grid component='span'>&times;</Grid>
              </Button>
            </Grid>
            <Grid sx={{ position: 'relative', flex: '1 1 auto' }}>
              <Grid
                sx={{
                  height: 'auto',
                  minHeight: 300,
                  backgroundColor: 'white',
                  margin: 'auto ',
                  width: { xs: '90%', sm: '75%' },
                  borderRadius: 4,
                  fontSize: '18px',
                }}
              >
                <Grid
                  container
                  rowSpacing={2}
                  sx={{ textAlign: 'left', mb: 1 }}
                >
                  <Grid item sm={6} xs={12} sx={{ pt: '0 !important' }}>
                    <InputLabel sx={createOrderModalsx.inputLabel}>
                      {t('InPatient-ListOrders-FirstNameColumnTitle')}
                    </InputLabel>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {productModalData.firstName}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ pt: '0 !important' }}>
                    <InputLabel sx={createOrderModalsx.inputLabel}>
                      {t('InPatient-ListOrders-LastNameColumnTitle')}
                    </InputLabel>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {productModalData.lastName}
                    </Typography>
                  </Grid>
                  <Grid container pt={0}>
                    <InputLabel sx={createOrderModalsx.inputLabel}>
                      {t('PatientInsurance-Edit-DateofBirthPrompt')}
                    </InputLabel>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          mask='__/__/____'
                          disableFuture
                          inputRef={focusInput}
                          inputFormat='MM/dd/yyyy'
                          value={formik.values.dateOfBirth}
                          onChange={(e: any) => {
                            if (e) {
                              const newDate = new Date(e);
                              newDate.setHours(0, 0, 0);
                              formik.handleChange({
                                target: {
                                  value: newDate,
                                  name: 'dateOfBirth',
                                },
                              });
                            } else {
                              formik.handleChange({
                                target: {
                                  value: e,
                                  name: 'dateOfBirth',
                                },
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...formik.getFieldProps('dateOfBirth')}
                              onChange={(e: any) =>
                                formik.handleChange({
                                  target: {
                                    value: e,
                                    name: 'dateOfBirth',
                                  },
                                })
                              }
                              fullWidth
                              {...params}
                              sx={{
                                '.MuiInputBase-formControl': {
                                  color: '#899dad',
                                  fontSize: 13,
                                  backgroundColor: '#F5F6FA',
                                  height: 40,
                                  margin: 0,
                                  maxWidth: '100%',
                                },
                                fieldset: {
                                  border: '1px solid #899dad ',
                                },
                              }}
                              error={
                                formik.errors.dateOfBirth === 'Invalid Date' ||
                                fieldHasError('dateOfBirth')
                              }
                              helperText={
                                formik.errors.dateOfBirth === 'Invalid Date'
                                  ? formik.errors.dateOfBirth
                                  : getFieldHelperText('dateOfBirth')
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <InputLabel sx={createOrderModalsx.inputLabel}>
                      {t('Deliveries-Details-SerialNumber')}
                    </InputLabel>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={9} sm={11}>
                        <TextField
                          type='text'
                          {...formik.getFieldProps('serialNumber')}
                          error={fieldHasError('serialNumber')}
                          helperText={getFieldHelperText('serialNumber')}
                          onChange={(e: any) => [
                            handleSerialNumberChange(e.target.value),
                          ]}
                          placeholder={t('NewLC-AssignPump-EnterOrScanSerial')}
                          sx={{
                            '.MuiInputBase-formControl': {
                              color: '#899dad',
                              fontSize: 13,
                              backgroundColor: '#F5F6FA',
                              height: 40,
                              margin: 0,
                            },
                            maxWidth: '100%',
                            width: '100%',
                          }}
                        />
                      </Grid>

                      <Grid item xs={3} sm={1} sx={{ display: 'grid' }}>
                        <Button
                          onClick={() => {
                            setIsQRCodeModalOpen(true);
                          }}
                          sx={{
                            backgroundColor: '#4a6b89',
                            color: 'white',
                            fontSize: '1.5rem',
                            justifyContent: 'center',
                            textTransform: 'uppercase',
                            minWidth: 40,
                            height: 40,
                            '&:hover': {
                              background: '#4a6b89',
                            },
                            p: 0,
                          }}
                        >
                          <InputLabel
                            htmlFor='attach-photo'
                            id='ariaLabelledby'
                            sx={{
                              color: 'white',
                              backgroundColor: '#4a6b89',
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: 0.6,
                              margin: 0.6,
                              textTransform: 'initial',
                            }}
                          >
                            <CameraEnhanceIcon />
                          </InputLabel>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexShrink: 0,
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0.75rem',
                border: 'none',
                borderBottomRightRadius: 'calc(0.3rem - 1px)',
                borderBottomLeftRadius: 'calc(0.3rem - 1px)',
              }}
            >
              <Stack
                direction='row'
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant='contained'
                  sx={{
                    background: '#E7EEF7 !important',
                    color: '#4A6B89',
                    marginRight: '15px',
                    textTransform: 'capitalize',
                    boxShadow: 'none !important',
                    fontSize: '15px',
                    fontWeight: '700 !important',
                  }}
                  onClick={(e: any) => handleReset(e)}
                >
                  {t('InPatient-ProvidePump-CancelButton')}
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    background: '#E6863E !important',
                    color: '#fff',
                    textTransform: 'capitalize',
                    boxShadow: 'none !important',
                    fontSize: '15px',
                    fontWeight: '700 !important',
                  }}
                  type='submit'
                >
                  {t('InPatient-ProvidePump-AssignButton')}
                </Button>
              </Stack>
            </Grid>
            <Dialog open={isQRCodeModalOpen} fullWidth keepMounted>
              <DialogContent>
                <BarcodeScannerModal
                  isQRCodeModalOpen={isQRCodeModalOpen}
                  onProductDataChange={handleSerialNumberChange}
                  onClose={handleQRModalClose}
                  isCloseModal
                />
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{
                    background: '#E7EEF7 !important',
                    color: '#4A6B89',
                    marginRight: '15px',
                    textTransform: 'capitalize',
                    boxShadow: 'none !important',
                    fontSize: '15px',
                    fontWeight: '700 !important',
                  }}
                  onClick={() => {
                    setIsQRCodeModalOpen(false);
                  }}
                >
                  {t('ClosePrompt')}
                </Button>
              </DialogActions>
            </Dialog>
            {isSignatureModalOpen && (
              <SignatureModal
                isSignatureModal={isSignatureModalOpen}
                handleCloseModal={handleCloseSignatureModal}
                productModalData={productModalData}
                onProductDataChange={onProductDataChange}
                handleAssignProductModalClose={handleAssignProductModalClose}
                getOrders={getOrders}
              />
            )}
          </form>
        </Box>
      </Modal>
      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
