import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppLayout } from './common/components/AppLayout/AppLayout';
import { PrivateRoute } from './common/components/PrivateRoutes/PrivateRoutes';
import { store } from './common/components/Redux/Store';
import {
  defaultI18nLanguage,
  supportedI18nLanguage,
} from './common/utils/Constants';
import './index.css';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Inventory } from './pages/Inventory/Inventory';
import { Shelf } from './pages/Inventory/Shelf';
import { NotFound } from './pages/NotFound/NotFound';
import { Orders } from './pages/Orders/Orders';
import { UserList } from './pages/Users/UserList';
import { UserLogin } from './pages/Users/UserLogin';
import {
  apiUrl,
  apiVersion,
  sentryDsn,
  sentryEnvironment,
  sentrySampleRate,
  SW_INIT,
  SW_UPDATE,
} from './utils/Constants';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const i18nCacheExpirationTime = 8 * 60 * 60 * 1000;
const i18nEndpoint = `${apiUrl}/api/v${apiVersion}/i18n`;

const setupI18n = () =>
  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      load: 'currentOnly',
      fallbackLng: defaultI18nLanguage,
      supportedLngs: supportedI18nLanguage,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            prefix: 'i18n-',
            expirationTime: i18nCacheExpirationTime,
            store: typeof window !== 'undefined' ? window.localStorage : null,
          },
          {
            loadPath: `${i18nEndpoint}?language={{lng}}`,
          },
        ],
      },
    });

const renderApp = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path='/'>
            <Route path='users' element={<UserList />} />
            <Route path='userLogin' element={<UserLogin />} />
            <Route path='inventory'>
              <Route
                index
                element={
                  <AppLayout>
                    <PrivateRoute>
                      <Inventory />
                    </PrivateRoute>
                  </AppLayout>
                }
              />
              <Route
                path='moveToShelf'
                element={
                  <AppLayout>
                    <PrivateRoute>
                      <Shelf />
                    </PrivateRoute>
                  </AppLayout>
                }
              />
            </Route>
            <Route
              index
              element={
                <AppLayout>
                  <Dashboard />
                </AppLayout>
              }
            />
            <Route path='orders'>
              <Route
                index
                element={
                  <AppLayout>
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  </AppLayout>
                }
              />
            </Route>
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
};

setupI18n()
  .then(() => {
    if (sentryDsn && sentrySampleRate && sentryEnvironment) {
      Sentry.init({
        dsn: sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: Number(sentrySampleRate),
        environment: sentryEnvironment,
      });
    }

    renderApp();
  })
  .catch(() => {
    // TODO: handle error
  });
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
});
