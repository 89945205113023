import { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Box,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Icon,
  Table,
  TableHead,
  useMediaQuery,
  TableSortLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { Cookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssignProductModal } from '../../components/AssignProductModal/AssignProductModal';
import { TablePagination } from '../../common/components/TableLayout/TablePagination';
import { Order } from '../../models/Order';
import { CreateOrderModal } from '../../components/CreateOrderModal/CreateOrderModal';
import { SessionExpireModal } from '../../components/SessionExpireModal/SessionExpireModal';
import { Product } from '../../models/Product';
import { OrderStatues } from '../../common/utils/Constants';
import 'react-toastify/dist/ReactToastify.css';
import { getAllOrdersAction } from '../../common/components/Redux/Actions/OrdersAction';
import { NotifyLcLoginModal } from '../../components/NotifyLcLoginModal/NotifyLcLoginModal';
import { getNotificationMessage } from '../../services/OrderService';

interface Data {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  status: string;
}

function descendingComparator<T>(a: T, b: T, SortOrderBy: keyof T) {
  if (b[SortOrderBy] < a[SortOrderBy]) {
    return -1;
  }
  if (b[SortOrderBy] > a[SortOrderBy]) {
    return 1;
  }
  return 0;
}

type SortOrder = 'asc' | 'desc' | false;

function getComparator<Key extends keyof any>(
  SortOrder: SortOrder,
  SortOrderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  if (SortOrder === 'desc') {
    return (a, b) => descendingComparator(a, b, SortOrderBy);
  }
  if (SortOrder === 'asc')
    return (a, b) => -descendingComparator(a, b, SortOrderBy);
  return (a) => descendingComparator(a, a, SortOrderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const SortOrder = comparator(a[0], b[0]);
    if (SortOrder !== 0) {
      return SortOrder;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  SortOrder: SortOrder;
  SortOrderBy: string;
}
interface OrderProps {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  status: string;
  language: string;
}
const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { SortOrder, SortOrderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: any) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();
  const headCells: readonly HeadCell[] = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: t('NewLC-OrderList-OrderNumber'),
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: false,
      label: t('InPatient-AddInPatientOrder-FirstNameField'),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('InPatient-AddInPatientOrder-LastNameField'),
    },
    {
      id: 'dateOfBirth',
      numeric: false,
      disablePadding: false,
      label: t('PatientInsurance-Edit-DateofBirthPrompt'),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('InPatient-ListOrders-StatusColumnTitle'),
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={SortOrderBy === headCell.id ? SortOrder : undefined}
          >
            <TableSortLabel
              active={SortOrderBy === headCell.id && SortOrder !== false}
              direction={
                SortOrderBy === headCell.id && SortOrder !== false
                  ? SortOrder
                  : 'asc'
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export const Orders: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { orders, loading } = useSelector((state: any) => state.OrdersReducer);
  const { state }: any = useLocation();
  const matches = useMediaQuery('(min-width:500px)');
  const [SortOrder, setNextOrderState] = useState<SortOrder>(false);
  const [SortOrderBy, setOrderBy] = useState<keyof Data>('id');
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
  const [searchOrderList, setSearchOrderList] = useState<any>('');
  const [searchStatus, setSearchStatus] = useState<any>('');
  const [searchStatusName, setsearchStatusName] = useState<any>('');
  const [productModalData, setProductModalData] = useState<Product>(
    new Product()
  );
  const cookies = new Cookies();
  const pageLimit: number = 10;
  const [page, setPage] = useState<number>(1);
  const [birthDate, setBirthDate] = useState<string>('');
  const facilityId = state?.facilityId
    ? state?.facilityId
    : secureLocalStorage.getItem('facilityId');
  const userId = state?.userId
    ? state?.userId
    : secureLocalStorage.getItem('userId');
  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] =
    useState<boolean>(false);
  const [isNotifyLcLoginModalOpen, setIsNotifyLcLoginModalOpen] =
    useState<boolean>(false);
  const [notifyContent, setNotifyContent] = useState('');
  const notify = (message: any) => {
    toast.error(message, { position: 'top-center' });
  };
  const handleRequestSort = (event: any, property: keyof Data) => {
    const isCurrentAsc = SortOrderBy === property && SortOrder === 'asc';
    const isCurrentDesc = SortOrderBy === property && SortOrder === 'desc';
    if (isCurrentAsc) {
      setNextOrderState('desc');
    } else if (isCurrentDesc) {
      setNextOrderState(false);
    } else {
      setNextOrderState('asc');
    }

    setOrderBy(property);
  };
  const orderMatchesSearchList = (value: string) =>
    value?.toLowerCase().indexOf(searchOrderList?.toLowerCase()) !== -1;

  const statusMatchesSearchList = (value: string) =>
    value?.toLowerCase().indexOf(searchStatus?.toLowerCase()) !== -1;
  const filteredOrders = orders.filter((data: OrderProps) => {
    if (
      searchStatus?.toLowerCase() !== 'all status' &&
      searchOrderList?.toLowerCase() !== 'all status'
    ) {
      return (
        (orderMatchesSearchList(data?.id?.toString()) ||
          orderMatchesSearchList(data.firstName) ||
          orderMatchesSearchList(data.lastName) ||
          orderMatchesSearchList(data.dateOfBirth)) &&
        statusMatchesSearchList(data.status)
      );
    }
    if (searchStatus?.toLowerCase() !== 'all status') {
      return statusMatchesSearchList(data.status);
    }
    return (
      orderMatchesSearchList(data?.id?.toString()) ||
      orderMatchesSearchList(data.firstName) ||
      orderMatchesSearchList(data.lastName) ||
      orderMatchesSearchList(data.dateOfBirth) ||
      orderMatchesSearchList(data.status)
    );
  });
  const onOpen = () => {
    setIsProductModalOpen(true);
  };
  const handleAssignProductModalClose = () => {
    setIsProductModalOpen(false);
    setProductModalData(new Product());
  };

  const handleCreateOrderModalClose = () => {
    setIsCreateOrderModalOpen(false);
  };
  const handleNotifyLcLoginModalClose = () => {
    setIsNotifyLcLoginModalOpen(false);
  };
  const handleProductDataChange = (order: Order) => {
    setProductModalData({
      ...productModalData,
      firstName: order.firstName,
      lastName: order.lastName,
      orderId: order.id,
      dateOfBirth: order.dateOfBirth,
      language: order.language,
      facilityId,
      userId,
    });
    setBirthDate(order.dateOfBirth);
  };
  const getOrders = async () => {
    try {
      dispatch(getAllOrdersAction({ facilityId, t }));
    } catch (error: any) {
      if (error.response.data.error === 'Unauthorized') {
        navigate(`/users?facilityId=${state?.facilityId}`, {
          state: { facilityId: state?.facilityId, userId: state?.userId },
        });
      }
      notify(error?.response?.data?.message);
    }
  };
  const getNotificationContent = async () => {
    const data = await getNotificationMessage();
    setNotifyContent(data?.value);
  };
  const handleNewOrder = () => {
    setIsCreateOrderModalOpen(true);
  };
  const handleSearchOrderList = (value: any) => {
    setSearchOrderList(value);
  };
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const isMobileDevice = regExp.test(details);
  const hospital: any = secureLocalStorage.getItem('hospitalName');

  useEffect(() => {
    if (state?.session === localStorage.getItem('session')) {
      getOrders();
      const notifyModalOpen = localStorage.getItem('isNotifyLcLoginModalOpen');
      if (notifyModalOpen !== 'true') {
        setIsNotifyLcLoginModalOpen(true);
        getNotificationContent();
        localStorage.setItem('isNotifyLcLoginModalOpen', 'true');
      }
    } else {
      navigate(`/users?facilityId=${secureLocalStorage.getItem('facilityId')}`);
    }
  }, []);
  useEffect(() => {
    setPage(1);
  }, [searchStatus, searchOrderList]);

  return (
    <Box
      sx={{
        pt: isMobileDevice ? 1.5 : 5,
        justifyContent: 'space-between',
        maxWidth: '100%',
      }}
    >
      <>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              width: 'fit-content',
              mb: '15px',
              fontSize: '18px',
              fontWeight: 600,
            }}
          >
            {hospital}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography
            variant='h5'
            component='h5'
            sx={{
              textAlign: 'left',
              width: 'fit-content',
              fontSize: { xs: '16px', sm: '22px' },
              mb: '14px',
            }}
          >
            {t('NewLC-OrderList-OrderTitle')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            sx={{
              position: 'relative',
              flex: '1 1 auto',
              pt: isMobileDevice ? '8px !important' : '16px',
              maxWidth: isMobileDevice ? '50%' : '100%',
            }}
            item
            xs={12}
            sm={3}
          >
            <TextField
              sx={{
                minWidth: 120,
                textAlign: 'left',
                mt: 0,
                outline: 0,
                backgroundColor: '#f5f6fa',

                Input: { py: 1.11, px: 0.6 },
              }}
              onChange={(e) => handleSearchOrderList(e.target.value)}
              value={searchOrderList}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon>
                      <SearchIcon />
                    </Icon>
                  </InputAdornment>
                ),
                endAdornment: searchOrderList && (
                  <InputAdornment
                    position='end'
                    onClick={() => handleSearchOrderList('')}
                  >
                    <Icon>
                      <CloseIcon sx={{ fontSize: '15px' }} />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              autoComplete='false'
              type='text'
              placeholder={t('SearchPrompt')}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              height: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              maxWidth: isMobileDevice ? '50%' : '100%',
              pt: isMobileDevice ? '8px !important' : '16px',
            }}
          >
            <Select
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              sx={{
                width: matches ? 200 : '100%',
                height: 40,
                textAlign: 'left',
                borderRadius: 0.5,
                py: 0.11,
                px: 0.5,
                backgroundColor: 'white',
                borderLeft: 0,
              }}
              onChange={(e) => {
                let name = '';
                if (e.target.value === 'IN_REVIEW') {
                  name = t('InPatient-ListOrders-InReviewMessage');
                } else if (e.target.value === 'ABANDONED') {
                  name = t('NewLC-OrderList-Abandoned');
                } else if (e.target.value === 'CANCELED') {
                  name = t('InPatient-ListOrders-CanceledMessage');
                } else if (e.target.value === 'DELIVERED') {
                  name = t('InPatient-ListOrders-DeliveredMessage');
                } else if (e.target.value === 'PROVIDE_PUMP') {
                  name = t('InPatient-ProvidePump-Title');
                } else {
                  name = t('NewLC-OrderList-AllStatus');
                }
                setSearchStatus(e.target.value);
                setsearchStatusName(name);
              }}
              defaultValue={t('NewLC-OrderList-AllStatus')}
              displayEmpty
              renderValue={() => (
                <Box sx={{ display: 'flex', gap: isMobileDevice ? 0 : 1 }}>
                  <SvgIcon sx={{ backgroundColor: 'white', color: '#6a859e' }}>
                    <FilterAltOutlinedIcon />
                  </SvgIcon>
                  {searchStatusName !== ''
                    ? searchStatusName
                    : t('NewLC-OrderList-AllStatus')}
                </Box>
              )}
            >
              <MenuItem value='ALL STATUS'>
                {t('NewLC-OrderList-AllStatus')}
              </MenuItem>
              {OrderStatues.map(
                (status) =>
                  status !== 'COMPLETED' &&
                  status !== 'REJECTED' && (
                    <MenuItem value={status} key={status}>
                      {status === 'IN_REVIEW'
                        ? t('InPatient-ListOrders-InReviewMessage')
                        : null}
                      {status === 'ABANDONED'
                        ? t('NewLC-OrderList-Abandoned')
                        : null}
                      {status === 'CANCELED'
                        ? t('InPatient-ListOrders-CanceledMessage')
                        : null}
                      {status === 'DELIVERED'
                        ? t('InPatient-ListOrders-DeliveredMessage')
                        : null}
                      {status === 'PROVIDE_PUMP'
                        ? t('InPatient-ProvidePump-Title')
                        : null}
                    </MenuItem>
                  )
              )}
            </Select>
          </Grid>
          <Grid
            item
            sm
            sx={{
              display: { xs: 'flex', sm: 'block' },
              justifyContent: 'flex-end',
              flexDirection: {
                xs: isMobileDevice ? 'row' : 'column',
                sm: 'row',
              },
              alignItems: 'flex-end',
              width: { xs: '100%' },
              pt: isMobileDevice ? '8px !important' : '16px',
            }}
          >
            <Button
              onClick={handleNewOrder}
              sx={{
                backgroundColor: '#e6863e',
                textTransform: 'inherit',
                borderRadius: 2,
                border: 'none',
                color: ' white',
                m: '1% 1%',
                p: 0.8,
                float: 'right',
                minWidth: 115,
                '&:hover': {
                  background: '#ef9047',
                },
              }}
            >
              + {t('NewLC-OrderList-CreateOrder')}
            </Button>
            <Button
              onClick={() =>
                navigate('/inventory', {
                  state: {
                    session: state?.session,
                    facilityId: state?.facilityId,
                    userId: state?.userId,
                  },
                })
              }
              sx={{
                backgroundColor: '#4a6b89',
                textTransform: 'inherit',
                borderRadius: 2,
                border: 'none',
                color: ' white',
                m: '1% 1%',
                p: 0.8,
                float: 'right',
                minWidth: 115,
                '&:hover': {
                  background: '#6087ab',
                },
              }}
            >
              {t('InPatient-ListOrders-InventoryManagementBtn')}
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ overflowX: 'auto' }}>
          <Table
            sx={{
              minWidth: 650,
              mt: isMobileDevice ? 2.5 : 5,
              borderCollapse: 'separate',
              borderSpacing: '0 6px',
              border: 'none',
              backgroundColor: '#e7eef7',
              overflowX: 'auto',
              tr: {
                backgroundColor: 'white',
                paddingTop: 0,
                paddingBottom: 0,
                margin: 0,
              },
              th: {
                backgroundColor: '#f5f6fa',
                color: '#6a859e',
                height: 40,
                paddingTop: 0,
                paddingBottom: 0,
                margin: 0,
                textAlign: 'left !important',
                verticalAlign: 'middle',
                p: { xs: '0px' },
                pr: { xs: '10px !important' },
                whiteSpace: 'nowrap',
              },
              td: {
                textAlign: 'left',
                padding: { sm: '18px' },
                verticalAlign: 'middle',
              },
              '& th:first-child, td:first-child': {
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              },
              '& th:last-child, td:last-child': {
                borderBottomRightRadius: 10,
                borderTopRightRadius: 10,
              },
            }}
            aria-label='simple table'
          >
            <EnhancedTableHead
              SortOrder={SortOrder}
              SortOrderBy={SortOrderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredOrders.length > 0 && !loading ? (
                stableSort(
                  filteredOrders,
                  getComparator(SortOrder, SortOrderBy)
                )
                  .slice(
                    (page - 1) * pageLimit,
                    (page - 1) * pageLimit + pageLimit
                  )
                  .map((order: Order | any) => (
                    <TableRow hover key={order.id}>
                      <TableCell
                        sx={{
                          width: '30px',
                          pl: '35px !important',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {order.id}
                      </TableCell>
                      <TableCell
                        sx={{
                          p: { xs: '0px' },
                        }}
                      >
                        {order.firstName}
                      </TableCell>
                      <TableCell
                        sx={{
                          p: { xs: '0px' },
                        }}
                      >
                        {order.lastName}
                      </TableCell>
                      <TableCell
                        sx={{
                          p: { xs: '0px' },
                        }}
                      >
                        {moment(order.dateOfBirth).format('MM/DD/YYYY')}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          p: { xs: '0px !important' },
                        }}
                      >
                        {order?.status?.toLowerCase() === 'provide_pump' && (
                          <Button
                            sx={{
                              borderRadius: 1.5,
                              py: 0.37,
                              px: 1,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#4a6b89',
                              color: '#edf0f3',
                              textTransform: 'initial',
                              m: 0,
                              '&:hover': {
                                background: '#6087ab',
                              },
                            }}
                            onClick={() => {
                              onOpen();
                              handleProductDataChange(order);
                            }}
                          >
                            {t('InPatient-ListOrders-ProvidePumpButton')}
                          </Button>
                        )}
                        {order?.status?.toLowerCase() === 'in_review' && (
                          <Grid
                            sx={{
                              borderRadius: 1.5,
                              py: 0.63,
                              px: 1.25,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#e4efff',
                              color: '#4181df',
                              m: 0,
                            }}
                          >
                            {t('InPatient-ListOrders-InReviewMessage')}
                          </Grid>
                        )}
                        {order?.status?.toLowerCase() === 'abandoned' && (
                          <Grid
                            sx={{
                              borderRadius: 1.5,
                              py: 0.63,
                              px: 1.25,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#ede1ea',
                              color: '#c51e9b',
                            }}
                          >
                            Abandoned
                          </Grid>
                        )}
                        {order?.status?.toLowerCase() === 'canceled' && (
                          <Grid
                            sx={{
                              borderRadius: 1.5,
                              py: 0.63,
                              px: 1.25,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#fff0f0',
                              color: '#ff0000',
                              m: 0,
                            }}
                          >
                            {t('InPatient-ListOrders-CanceledMessage')}
                          </Grid>
                        )}
                        {order?.status?.toLowerCase() === 'delivered' && (
                          <Grid
                            sx={{
                              borderRadius: 1.5,
                              py: 0.63,
                              px: 1.25,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#daf4c0',
                              color: '#2d7836',
                            }}
                          >
                            {t('InPatient-ListOrders-DeliveredMessage')}
                          </Grid>
                        )}
                        {order?.status?.toLowerCase() === 'completed' && (
                          <Grid
                            sx={{
                              borderRadius: 1.5,
                              py: 0.63,
                              px: 1.25,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#fcecd2',
                              color: '#db831b',
                            }}
                          >
                            Completed
                          </Grid>
                        )}
                        {order?.status?.toLowerCase() === 'rejected' && (
                          <Grid
                            sx={{
                              borderRadius: 1.5,
                              py: 0.63,
                              px: 1.25,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#ffd8d8',
                              color: '#7d6262',
                            }}
                          >
                            Rejected
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableCell
                  colSpan={5}
                  sx={{ mt: 2, mb: 3, ml: 3, textAlign: 'center !important' }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: '#d32f2f' }} />
                  ) : (
                    (searchStatus !== '' || searchOrderList !== '') &&
                    t('NewLC-OrderList-NoMatchingRecord')
                  )}
                  {!loading &&
                    searchStatus === '' &&
                    searchOrderList === '' &&
                    `${t('NewLCOP-No')} ${t(
                      'ControlPanel-Index-OrderEditorPrompt'
                    )}`}
                </TableCell>
              )}
            </TableBody>
          </Table>
        </Grid>
        {filteredOrders.length > 0 && (
          <TablePagination
            page={page}
            pages={filteredOrders.length}
            onChange={setPage}
            pageLimit={pageLimit}
          />
        )}
        <AssignProductModal
          isProductModalOpen={isProductModalOpen}
          productModalData={productModalData}
          onProductDataChange={setProductModalData}
          handleAssignProductModalClose={handleAssignProductModalClose}
          getOrders={getOrders}
          birthDate={birthDate}
        />
        {isCreateOrderModalOpen && (
          <CreateOrderModal
            isCreateOrderModalOpen={isCreateOrderModalOpen}
            onClose={handleCreateOrderModalClose}
          />
        )}
        {isNotifyLcLoginModalOpen && notifyContent && (
          <NotifyLcLoginModal
            isNotifyLcLoginModalOpen={isNotifyLcLoginModalOpen}
            onClose={handleNotifyLcLoginModalClose}
            notifyContent={notifyContent}
          />
        )}
        {!cookies.get('accessToken') && (
          <SessionExpireModal
            navigateTo='userLogin'
            isSessionExpire={!cookies.get('accessToken')}
          />
        )}
        {state?.session !== localStorage.getItem('session') && (
          <SessionExpireModal
            navigateTo='userList'
            isSessionExpire={state?.session !== localStorage.getItem('session')}
          />
        )}
      </>
    </Box>
  );
};
