import {
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import {
  DataGrid,
  GridColDef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowParams,
} from '@mui/x-data-grid';
import secureLocalStorage from 'react-secure-storage';
import CloseIcon from '@mui/icons-material/Close';
import { BarcodeScannerModal } from '../QRCodeScanner/QRCodeScanner';
import { SessionExpireModal } from '../SessionExpireModal/SessionExpireModal';
import { NewUpdateVersionModal } from '../NewUpdateVersionModal/NewUpdateVersionModal';
import {
  inventoryMoveToShelfs,
  validateSerialNumber,
} from '../../services/InventoryService';
import './ScanInventoryModalCss.scss';

interface ScanInventoryModalProps {
  isScanInventoryModalOpen: boolean;
  onClose: (isSuccess: boolean) => void;
  playBeepSound: () => void;
}

interface SerialNumberProps {
  id: number;
  serialNumber: string;
}
const notify = (message: string, type: string) => {
  if (type === 'error') {
    toast.error(message, { position: 'top-center' });
  } else {
    toast.success(message, { position: 'top-center' });
  }
};
export const ScanInventoryModal: FunctionComponent<ScanInventoryModalProps> = ({
  isScanInventoryModalOpen,
  onClose,
  playBeepSound,
}) => {
  const [serialNumber, setSerialNumber] = useState<any>([]);
  const [manualSerialNumber, setManualSerialNumber] = useState<string>('');
  const [selectionModel, setSelectionModel] = useState<Array<number>>([]);
  const [qrError, setQrError] = useState<String>('');
  const [isScanned, setIsScanned] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState<boolean>(true);
  const cookies = new Cookies();
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const facilityId = state?.facilityId;
  const userId = state?.userId
    ? state?.userId
    : secureLocalStorage.getItem('userId');
  const { serviceWorkerUpdated } = useSelector(
    (serviceWorkerState: any) => serviceWorkerState.ServiceWorkerReducer
  );
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const dataGridRef = useRef<any>();
  const isMobileDevice = regExp.test(details);
  const flapStyles1: CSSProperties | any = { '--i': 0 };
  const flapStyles2: CSSProperties | any = { '--i': 1 };
  const flapStyles3: CSSProperties | any = { '--i': 2 };
  const flapStyles4: CSSProperties | any = { '--i': 3 };
  const flapStyles5: CSSProperties | any = { '--i': 4 };
  const flapStyles6: CSSProperties | any = { '--i': 5 };

  const scrollToLastRow = () => {
    const tableContainer = dataGridRef.current;
    if (tableContainer) {
      tableContainer.scrollTop = tableContainer.scrollHeight;
    }
  };

  const validateSerialNo = async (serialNo: string) => {
    let status = false;
    try {
      const response = await validateSerialNumber(serialNo);
      status = response?.isValid;
    } catch (error: any) {
      status = false;
      if (error === 'Unauthorized') {
        navigate(
          `/users?facilityId=${secureLocalStorage.getItem('facilityId')}`
        );
      } else {
        notify(`${error}`, 'error');
      }
    }
    return status;
  };

  const handleSerialNumberChange = (e: string, type: number) => {
    if (type === 0) {
      setIsScanned(true);
      playBeepSound();
    }
    toast.dismiss();
    const newSerialNumbers = e?.split('+');
    newSerialNumbers?.map(async (sno) => {
      const isValid = await validateSerialNo(sno);
      return setSerialNumber((oldSerialNumber: Array<SerialNumberProps>) => {
        const isSerialNoExists = oldSerialNumber.some(
          (serialNo: any) => serialNo.serialNumber === sno
        );
        setSelectionModel((oldSelectionModel: Array<number>) =>
          isSerialNoExists || isValid === false
            ? oldSelectionModel
            : [...oldSelectionModel, oldSerialNumber.length + 1]
        );
        return isSerialNoExists
          ? (notify('Serial number already scanned', 'error'), oldSerialNumber)
          : [
              ...oldSerialNumber,
              { id: oldSerialNumber.length + 1, serialNumber: sno, isValid },
            ];
      });
    });
    if (type === 1) {
      setManualSerialNumber('');
    }
    setTimeout(() => {
      setIsScanned(false);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToLastRow();
    }, 500);
  }, [serialNumber]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 60 },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      width: isMobileDevice ? 214 : 570,
      renderCell: (params: any) => {
        const textColor = params?.row?.isValid === false ? 'red' : 'black';
        return (
          <span style={{ color: textColor, overflow: 'auto' }}>
            {params?.row?.serialNumber}
          </span>
        );
      },
    },
    // {
    //   ...GRID_CHECKBOX_SELECTION_COL_DEF,
    //   width: 0,
    // },
  ];

  const handleSubmit = async () => {
    try {
      const serialNumbers: Array<string> = [];
      serialNumber?.map(
        (sData: SerialNumberProps) =>
          selectionModel.includes(sData?.id) &&
          serialNumbers.push(sData?.serialNumber)
      );
      const response: any = await inventoryMoveToShelfs(
        facilityId,
        serialNumbers.join(','),
        userId
      );
      if (response?.data?.invalidSerialNumbers?.length > 0) {
        notify(
          `Inventory Transfer does not exist for the following serial number(s) ${response.data.invalidSerialNumbers
            .toString()
            .replaceAll(',', ', ')}`,
          'error'
        );
      } else {
        notify('Serial Numbers are Moved to Shelf', 'success');
        onClose(true);
      }
    } catch (error: any) {
      if (error.response.data.error === 'Unauthorized') {
        navigate(`/users?facilityId=${state?.facilityId}`, {
          state: { facilityId: state?.facilityId, userId: state?.userId },
        });
      } else {
        notify(error?.response?.data?.message, 'error');
      }
    }
  };

  return (
    <div>
      <Modal
        hideBackdrop
        sx={{ overflowY: 'scroll', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        open={isScanInventoryModalOpen}
        onClose={() => onClose(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            top: '50%',
            left: '50%',
            transform: isMobileDevice
              ? 'translate(-50%, -45%)'
              : 'translate(-50%, -40%)',
            overflow: 'auto',
            width: 700,
            maxWidth: '90%',
            bgcolor: 'background.paper',
            p: 3,
            outline: 'none',
          }}
        >
          <Box>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Scan To Shelf
            </Typography>
            <Button
              sx={{
                position: 'absolute',
                right: '10px',
                top: '20px',
                color: '#000',
              }}
              data-testid='close-order-modal'
              endIcon={<CloseIcon />}
              onClick={() => onClose(false)}
            />
          </Box>
          {!qrError && (
            <Box
              sx={{
                p: { xs: 3, sm: 5 },
              }}
            >
              <div className={`shutter-container ${isScanned ? 'open' : ''}`}>
                <BarcodeScannerModal
                  isQRCodeModalOpen={isQRCodeModalOpen}
                  onProductDataChange={handleSerialNumberChange}
                  isCloseModal={false}
                  setQrError={setQrError}
                />
                <div className='flap' style={flapStyles1} />
                <div className='flap' style={flapStyles2} />
                <div className='flap' style={flapStyles3} />
                <div className='flap' style={flapStyles4} />
                <div className='flap' style={flapStyles5} />
                <div className='flap' style={flapStyles6} />
              </div>

              {qrError && (
                <span
                  style={{
                    color: '#d32f2f',
                    fontSize: '1rem',
                    fontWeight: '400',
                    marginTop: '3px',
                    marginRight: 14,
                    marginBottom: 0,
                    marginLeft: '14px',
                  }}
                >
                  {qrError}
                </span>
              )}
            </Box>
          )}
          <Grid
            container
            sx={{
              py: 2,
            }}
          >
            <Grid container columnSpacing={1}>
              <Grid item xs={9} sm={10}>
                <TextField
                  type='text'
                  onChange={(e) => {
                    setManualSerialNumber(e.target.value.toUpperCase());
                  }}
                  autoFocus={!isMobileDevice}
                  value={manualSerialNumber}
                  placeholder='Enter Serial Number'
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                  sx={{
                    '.MuiInputBase-formControl': {
                      color: '#899dad',
                      fontSize: 13,
                      backgroundColor: '#F5F6FA',
                      height: 40,
                      margin: 0,
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#4a6b89',
                      },
                    },
                    maxWidth: '100%',
                    width: '100%',
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={2} sx={{ display: 'grid' }}>
                <Button
                  onClick={() =>
                    manualSerialNumber.trim() !== '' &&
                    handleSerialNumberChange(manualSerialNumber, 1)
                  }
                  variant='contained'
                  disabled={manualSerialNumber.trim() === ''}
                  sx={{
                    backgroundColor: '#4a6b89 !important',
                    color: '#fff !important',
                    textTransform: 'capitalize',
                    boxShadow: 'none !important',
                    fontSize: '14px',
                    fontWeight: '700 !important',
                    minWidth: 40,
                    height: 40,
                    '&:hover': {
                      background: '#6087ab !important',
                    },
                    '&.Mui-disabled': {
                      background: '#79a4cc !important',
                      color: '#fff !important',
                    },
                    p: 0,
                  }}
                >
                  + Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {serialNumber && serialNumber?.length !== 0 && (
            <div
              ref={dataGridRef}
              style={{
                maxHeight: 250,
                width: '100%',
                overflow: 'auto',
              }}
            >
              <DataGrid
                rows={serialNumber}
                columns={columns}
                hideFooter
                // checkboxSelection
                // rowSelectionModel={selectionModel}
                // onRowSelectionModelChange={(e: any) => {
                //   setSelectionModel(e);
                // }}
                sx={{
                  '& .MuiDataGrid-columnHeadersInner': {
                    transform: 'none !important',
                  },
                  '& .MuiDataGrid-virtualScrollerRenderZone': {
                    transform: 'none !important',
                  },
                }}
                isRowSelectable={(params: GridRowParams) =>
                  params.row.isValid === true
                }
                disableColumnSelector
                disableColumnMenu
                disableColumnFilter
              />
            </div>
          )}
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexShrink: 0,
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '0.75rem',
              border: 'none',
              borderBottomRightRadius: 'calc(0.3rem - 1px)',
              borderBottomLeftRadius: 'calc(0.3rem - 1px)',
            }}
          >
            <Stack
              direction='row'
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                '.Mui-disabled': {
                  background: '#ef9047bd!important',
                  color: '#fff !important',
                },
              }}
            >
              <Button
                variant='contained'
                sx={{
                  background: '#E7EEF7 !important',
                  color: '#4A6B89',
                  marginRight: '15px',
                  textTransform: 'capitalize',
                  boxShadow: 'none !important',
                  fontSize: '15px',
                  fontWeight: '700 !important',
                }}
                onClick={() => onClose(false)}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                sx={{
                  background: '#E6863E !important',
                  color: '#fff',
                  textTransform: 'capitalize',
                  boxShadow: 'none !important',
                  fontSize: '15px',
                  fontWeight: '700 !important',
                }}
                disabled={selectionModel?.length <= 0}
                onClick={() => handleSubmit()}
                type='submit'
              >
                Move To Shelf
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal>
      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </div>
  );
};
