import { CircularProgress, Grid, Modal, Box } from '@mui/material';
import { Html5Qrcode } from 'html5-qrcode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

interface BarcodeScannerProps {
  isQRCodeModalOpen: boolean;
  onProductDataChange: Function;
  onClose?: any;
  isCloseModal: boolean;
  setQrError?: String | any;
}

interface QrCodeProps {
  fps: number;
  aspectRatio: number;
}
const notify = (message: any) => {
  toast.error(message, { position: 'top-center' });
};
export const BarcodeScannerModal: FunctionComponent<BarcodeScannerProps> = ({
  isQRCodeModalOpen,
  onProductDataChange,
  onClose,
  isCloseModal,
  setQrError,
}) => {
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const isMobileDevice = regExp.test(details);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const barcodeScannerContainer = useRef<HTMLDivElement | null>(null);
  const qrCodeProps: QrCodeProps = {
    fps: 1,
    aspectRatio: isMobileDevice ? 1 : 1.333334,
  };
  useEffect(() => {
    const html5QrCode = new Html5Qrcode('reader');
    function onScanSuccess(decodedText: string) {
      onProductDataChange(decodedText, 0);
      html5QrCode.pause(true);
      setTimeout(() => {
        html5QrCode.resume();
      }, 2000);
      if (isCloseModal) {
        html5QrCode
          .stop()
          .then(() => {
            html5QrCode.clear();
            onClose();
          })
          .catch((err) => err);
      }
    }
    function onScanFailure(error: string) {
      setIsError(true);
      return error;
    }
    if (isQRCodeModalOpen === true) {
      (async () => {
        setLoading(true);
        await html5QrCode
          .start(
            { facingMode: 'environment' },
            qrCodeProps,
            onScanSuccess,
            onScanFailure
          )
          .then(() => setLoading(false))
          .catch(
            (error) =>
              error &&
              (notify(
                'Your device doesn’t have camera access, Please enter serial number(s) manually'
              ),
              isCloseModal && onClose(),
              setLoading(false),
              setQrError && setQrError(error))
          );
      })();
    }
    return () => {
      if (!isError && isMobileDevice && !isCloseModal) {
        toast.dismiss();
        html5QrCode.stop();
      }
    };
  }, [isQRCodeModalOpen]);
  return (
    <>
      <Grid
        id='reader'
        data-testid='reader'
        ref={barcodeScannerContainer}
        sx={{
          '& div': {
            display: 'none !important',
          },
        }}
      />
      {loading && (
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: ' 30px 0',
            overflowY: 'auto',
            '.input-error': {
              border: '1px solid #d32f2f!important',
            },
          }}
          open={loading}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={{
              borderRadius: 4,
              position: 'relative',
              width: 800,
              maxWidth: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 3,
              overflowY: 'auto',
              margin: 'auto',
              outline: 'none',
            }}
          >
            <CircularProgress sx={{ color: '#E6863E' }} />
          </Box>
        </Modal>
      )}
    </>
  );
};
