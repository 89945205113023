import { StoreAction } from '../Action';

const initialState = {
  inventory: [],
  loading: true,
};

const InventoryReducer = (
  state = initialState,
  { type, payload }: any = {}
) => {
  switch (type) {
    case StoreAction.GET_INVENTORY:
      return {
        ...state,
        inventory: payload,
        loading: false,
      };
    case StoreAction.SET_INVENTORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
export default InventoryReducer;
