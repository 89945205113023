import {
  Button,
  Checkbox,
  Grid,
  Typography,
  Box,
  Modal,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Cookies } from 'react-cookie';
import React, { FunctionComponent } from 'react';
import SignaturePad from 'react-signature-canvas';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Product } from '../../models/Product';
import { assignPump } from '../../services/OrderService';
import 'react-toastify/dist/ReactToastify.css';
import { changeI18nLanguage } from '../../common/utils/Utils';
import { SessionExpireModal } from '../SessionExpireModal/SessionExpireModal';
import { NewUpdateVersionModal } from '../NewUpdateVersionModal/NewUpdateVersionModal';

interface SignatureProps {
  isSignatureModal: boolean;
  handleCloseModal: () => void;
  onProductDataChange: Function;
  productModalData: Product;
  handleAssignProductModalClose: () => void;
  getOrders: () => void;
}

const notify = (message: any) => {
  toast.error(message, { position: 'top-center' });
};

export const SignatureModal: FunctionComponent<SignatureProps> = ({
  isSignatureModal,
  handleCloseModal,
  onProductDataChange,
  productModalData,
  handleAssignProductModalClose,
  getOrders,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { state }: any = useLocation();
  const padRef = React.useRef<SignaturePad>(null);
  const programOptIn = secureLocalStorage.getItem('programOptIn');
  const [loading, setLoading] = React.useState(false);
  const { serviceWorkerUpdated } = useSelector(
    (serviceWorkerState: any) => serviceWorkerState.ServiceWorkerReducer
  );
  const [signatureSize, setSignatureSize] = React.useState<number>(0);
  const [isCancelModalOpen, setIsCancelModalOpen] =
    React.useState<boolean>(false);
  const validationSchema = object({
    signatureEncodedImg: string().required(
      t('InPatient-ConfirmSignature-ProvideSignaturePrompt')
    ),
  });
  const formik = useFormik({
    initialValues: new Product(),
    validationSchema,
    onSubmit: async () => {
      try {
        setLoading(true);
        const blobFile = await fetch(
          `data:image/png;base64,${productModalData?.signatureEncodedImg}`
        ).then((res) => res.blob());
        const signatureFile = new File([blobFile], 'signature.png', {
          type: 'image/png',
        });
        const formData: any = new FormData();
        formData.append('userId', productModalData?.userId);
        formData.append('facilityId', productModalData?.facilityId);
        formData.append('firstName', productModalData?.firstName);
        formData.append('lastName', productModalData?.lastName);
        formData.append('dateOfBirth', productModalData?.dateOfBirth);
        formData.append('serialNumber', productModalData?.serialNumber);
        formData.append('signatureFile', signatureFile);
        formData.append('optIn', productModalData?.optIn);
        await assignPump(formData, productModalData?.orderId);
        setLoading(false);
        handleCloseModal();
        handleAssignProductModalClose();
        changeI18nLanguage('English');
        getOrders();
      } catch (error: any) {
        if (error.response.data.error === 'Unauthorized') {
          navigate(
            `/users?facilityId=${secureLocalStorage.getItem('facilityId')}`
          );
        }
        notify(error.response.data.message);
        setLoading(false);
        // TODO handle error
      }
    },
  });
  const handleClearSignaturePad = () => {
    padRef.current?.clear();
    const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png');
    if (url) {
      onProductDataChange({
        ...productModalData,
        signatureEncodedImg: '',
      });
      formik.handleChange({
        target: { value: '', name: 'signatureEncodedImg' },
      });
      setSignatureSize(0);
    }
  };
  const handleOptInChange = (e: boolean) => {
    onProductDataChange({
      ...productModalData,
      optIn: e,
    });
  };
  React.useEffect(() => {
    changeI18nLanguage(
      productModalData.language === 'es-ES' ? 'Espanol' : 'English'
    );
  }, []);
  const loadImage = (url: any) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.crossOrigin = 'anonymous';
      img.src = url;
    });

  const handleSignatureSubmit = async () => {
    const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png');
    if (url) {
      onProductDataChange({
        ...productModalData,
        signatureEncodedImg: url.split(',')[1],
      });
      const urlData = padRef.current?.getCanvas().toDataURL('image/png');
      const img: any = await loadImage(urlData);
      const ctx: HTMLCanvasElement | any = document
        .createElement('canvas')
        .getContext('2d');
      ctx.canvas.width = img.width;
      ctx.canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
      let pixels = 0;
      for (let y = 0, i = 0; y < img.height; y += 1)
        for (let x = 0; x < img.width; x += 1, i += 4) {
          if (imageData[i + 3]) {
            pixels += 1;
          }
        }
      setSignatureSize(pixels);
      if (pixels > 700) {
        formik.handleChange({
          target: { value: url.split(',')[1], name: 'signatureEncodedImg' },
        });
      }
    }
  };
  const getFieldHelperText = (field: keyof Product): string | undefined =>
    formik.touched[field] ? formik.errors[field] : undefined;
  const handleReset = (e: React.HTMLInputTypeAttribute) => {
    handleCloseModal();
    formik.handleReset(e);
  };
  const handleOpenSignatureModal = () => {
    setIsCancelModalOpen(true);
  };
  const handleCloseCancelModal = (val: boolean) => {
    if (val === true) {
      handleCloseModal();
      changeI18nLanguage('English');
    }
    setIsCancelModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isSignatureModal}
        onClose={handleCloseModal}
        hideBackdrop
        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <Box
          sx={{
            border: 'none',
            width: { sm: '70%', xs: '90%' },
            maxHeight: '90%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'auto',
            backgroundColor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '15px',
            outline: 'none',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Grid item xs={12}>
                <Typography variant='h5'>
                  {t('NewOP-ReviewPage-Signature')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Typography
                sx={{
                  textAlign: 'justify',
                  fontSize: 'medium',
                  fontWeight: 'bold',
                  mb: 3,
                }}
              >
                {t('InPatient-ConfirmSignature-SignatureTermsTitle')}
              </Typography>
            </Grid>
            <Grid sx={{ height: '300px', overflow: 'auto' }} mb={2}>
              <Typography
                variant='body1'
                sx={{ textAlign: 'justify', fontSize: 'small', mb: 3 }}
              >
                {t('NewLC-SignaturePage-TermsLegal')
                  .split('\n')
                  .map((str, index) => (
                    <p key={`terms${index.toString()}`}>{str}</p>
                  ))}
              </Typography>
            </Grid>
            {programOptIn === true && (
              <Grid
                sx={{
                  backgroundColor: '#eceef5',
                  border: '2px solid #bdccd9',
                  p: '15px',
                  borderRadius: '10px',
                  margin: '12px 0px',
                }}
              >
                <Typography variant='subtitle2' gutterBottom component='div'>
                  {t('NewLC-SignaturePage-TermsLegal').split('\n')[2]}
                </Typography>
                <Typography sx={{ fontWeight: '600' }}>
                  <Checkbox
                    sx={{ pl: 0 }}
                    checked={productModalData.optIn}
                    onChange={(e) => handleOptInChange(e.target.checked)}
                  />
                  {t('InPatient-ConfirmSignature-SignatureOptInLabel')}
                </Typography>
              </Grid>
            )}
            <Typography variant='h6' mt={2}>
              {t('InPatient-ConfirmSignature-SignatureSignTitle')}
            </Typography>
            <Grid
              container
              sx={{
                width: '100%',
                backgroundColor: '#eceef5',
                borderRadius: '10px',
                mt: '20px',
                m: '0 auto',
                ml: 0,
              }}
            >
              <Grid
                item
                xs={9}
                sm={11}
                sx={{ width: '100% !important', pl: 0 }}
              >
                <SignaturePad
                  ref={padRef}
                  clearOnResize={false}
                  canvasProps={{
                    style: {
                      width: '100%',
                      height: '150px',
                      marginTop: '10px',
                      marginLeft: '10px',
                    },
                  }}
                  onEnd={handleSignatureSubmit}
                />
              </Grid>
              {productModalData.signatureEncodedImg && (
                <Grid
                  item
                  xs={3}
                  sm={1}
                  sx={{ textAlign: { xs: 'center', sm: 'right' } }}
                >
                  <Button
                    sx={{
                      color: 'white',
                      backgroundColor: '#4a6b89',
                      borderRadius: '3px',
                      padding: '5px',
                      marginBottom: '5px',
                      minWidth: '10px',
                      fontSize: '1rem',
                      mt: { xs: '5px', sm: '10px' },
                      mr: { xs: '5px', sm: '10px' },
                      '&:hover': {
                        backgroundColor: '#4a6b89',
                        color: 'white',
                      },
                    }}
                    onClick={handleClearSignaturePad}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Grid>
              )}
            </Grid>
            {formik.touched.signatureEncodedImg &&
              formik.errors.signatureEncodedImg && (
                <span
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    fontWeight: '400',
                    marginTop: '3px',
                    marginRight: 14,
                    marginBottom: 0,
                    marginLeft: '14px',
                  }}
                >
                  {signatureSize < 700 && signatureSize >= 1
                    ? t('NewLC-Signature-PleaseEnter')
                    : getFieldHelperText('signatureEncodedImg')}
                </span>
              )}
            <Grid sx={{ paddingY: '15px', textAlign: 'right' }}>
              <Button
                variant='contained'
                sx={{
                  background: '#E7EEF7 !important',
                  color: '#4A6B89',
                  marginRight: '15px',
                  textTransform: 'capitalize',
                  boxShadow: 'none !important',
                  fontSize: '15px',
                  fontWeight: '700 !important',
                  borderRadius: 2,
                }}
                onClick={handleOpenSignatureModal}
              >
                {t('CancelPrompt')}
              </Button>
              <Button
                variant='contained'
                sx={{
                  background: '#E6863E !important',
                  color: '#fff',
                  textTransform: 'capitalize',
                  boxShadow: 'none !important',
                  fontSize: '15px',
                  fontWeight: '700 !important',
                  px: 4,
                  borderRadius: 2,
                }}
                disabled={loading}
                type='submit'
              >
                {t('InPatient-AddInPatientOrder-AddInPatientOrderSubmitBtn')}
              </Button>
            </Grid>
          </form>
        </Box>
      </Modal>
      {loading && (
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: ' 30px 0',
            overflowY: 'auto',
            '.input-error': {
              border: '1px solid #d32f2f!important',
            },
          }}
          open={loading}
          onClick={(e: any) => handleReset(e)}
        >
          <Box
            sx={{
              borderRadius: 4,
              position: 'relative',
              width: 800,
              maxWidth: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 3,
              overflowY: 'auto',
              margin: 'auto',
              outline: 'none',
            }}
          >
            <CircularProgress sx={{ color: '#E6863E' }} />
          </Box>
        </Modal>
      )}
      <Dialog
        open={isCancelModalOpen}
        sx={{ borderRadius: '8px !important', border: 'none' }}
      >
        <DialogContent>
          <DialogContentText sx={{ color: 'black' }}>
            {t('UserProfiles-Delete-ConfirmationPrompt')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: '#E7EEF7 !important',
              color: '#4A6B89',
              marginRight: '10px',
              textTransform: 'capitalize',
              boxShadow: 'none !important',
              fontSize: '15px',
              fontWeight: '700 !important',
            }}
            onClick={() => handleCloseCancelModal(false)}
          >
            {t('NewLCOP-No')}
          </Button>
          <Button
            sx={{
              background: '#E6863E !important',
              color: '#fff',
              textTransform: 'capitalize',
              boxShadow: 'none !important',
              fontSize: '15px',
              fontWeight: '700 !important',
              px: 2,
              borderRadius: 2,
            }}
            autoFocus
            onClick={() => handleCloseCancelModal(true)}
          >
            {t('NewLCOP-Yes')}
          </Button>
        </DialogActions>
      </Dialog>
      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
