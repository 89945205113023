import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Modal,
  Container,
  Button,
  Stack,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Cookies } from 'react-cookie';
import { SessionExpireModal } from '../SessionExpireModal/SessionExpireModal';
import { NewUpdateVersionModal } from '../NewUpdateVersionModal/NewUpdateVersionModal';
import './NotifyLcLoginModal.css';

interface NotifyLcLoginModalProps {
  isNotifyLcLoginModalOpen: boolean;
  onClose: () => void;
  notifyContent: string;
}

export const NotifyLcLoginModal: FunctionComponent<NotifyLcLoginModalProps> = ({
  isNotifyLcLoginModalOpen,
  onClose,
  notifyContent,
}) => {
  const cookies = new Cookies();
  const { state }: any = useLocation();
  const { serviceWorkerUpdated } = useSelector(
    (serviceWorkerState: any) => serviceWorkerState.ServiceWorkerReducer
  );

  return (
    <>
      <Modal
        hideBackdrop
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: ' 30px 0',
          overflowY: 'auto',
          '.input-error': {
            border: '1px solid #d32f2f!important',
          },
        }}
        open={isNotifyLcLoginModalOpen}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          className={
            isNotifyLcLoginModalOpen ? 'modalFadeEnter' : 'modalFadeExit'
          }
          sx={{
            borderRadius: 4,
            position: 'relative',
            width: 800,
            maxWidth: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            overflowY: 'auto',
            margin: 'auto',
            outline: 'none',
          }}
        >
          <Box mb={3}>
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              sx={{ pl: '20px', fontSize: '22px' }}
            >
              Alert
            </Typography>
            <Button
              sx={{
                position: 'absolute',
                right: '10px',
                top: '20px',
                color: '#000',
              }}
              data-testid='close-order-modal'
              endIcon={<CloseIcon />}
              onClick={onClose}
            />
          </Box>
          <Container>
          <Typography sx={{ height: '300px', overflow: 'auto' }}>
              {notifyContent.split('\n').map((str, index) => (
                <p key={`terms${index.toString()}`}>{str}</p>
              ))}
            </Typography>
            <Stack
              mt={5}
              direction='row'
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                '.Mui-disabled': {
                  background: '#ef9047bd!important',
                  color: '#fff !important',
                },
              }}
            >
              <Button
                variant='contained'
                sx={{
                  background: '#E6863E !important',
                  color: '#fff',
                  textTransform: 'capitalize',
                  boxShadow: 'none !important',
                  fontSize: '16px',
                  fontWeight: '700 !important',
                  padding: '6px 40px !important',
                  borderRadius: '6px',
                }}
                onClick={onClose}
                type='button'
              >
                OK
              </Button>
            </Stack>
          </Container>
        </Box>
      </Modal>

      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
