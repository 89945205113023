import { FunctionComponent, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Box,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableSortLabel,
} from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import { TablePagination } from '../../common/components/TableLayout/TablePagination';
import 'react-toastify/dist/ReactToastify.css';
import { inventoryMoveToShelf } from '../../services/InventoryService';
import { InventoryModel } from '../../models/Inventory';
import { SessionExpireModal } from '../../components/SessionExpireModal/SessionExpireModal';
import { getAllShelfAction } from '../../common/components/Redux/Actions/ShelfAction';

function descendingComparator<T>(a: T, b: T, SortInventoryBy: keyof T) {
  if (b[SortInventoryBy] < a[SortInventoryBy]) {
    return -1;
  }
  if (b[SortInventoryBy] > a[SortInventoryBy]) {
    return 1;
  }
  return 0;
}

type SortInventory = 'asc' | 'desc' | false;

function getComparator<Key extends keyof any>(
  SortInventory: SortInventory,
  SortInventoryBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  if (SortInventory === 'desc') {
    return (a, b) => descendingComparator(a, b, SortInventoryBy);
  }
  if (SortInventory === 'asc')
    return (a, b) => -descendingComparator(a, b, SortInventoryBy);
  return (a) => descendingComparator(a, a, SortInventoryBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const SortInventory = comparator(a[0], b[0]);
    if (SortInventory !== 0) {
      return SortInventory;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof InventoryModel;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InventoryModel
  ) => void;
  SortInventory: SortInventory;
  SortInventoryBy: string;
}
const cookies = new Cookies();

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { t } = useTranslation();
  const { SortInventory, SortInventoryBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof InventoryModel) => (event: any) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: 'model',
      numeric: false,
      disablePadding: false,
      label: t('DataExportFiles-Details-ModelPrompt'),
    },
    {
      id: 'serialNumber',
      numeric: false,
      disablePadding: false,
      label: t('Archive-Index-SerialNumberColumnHeader'),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('NewLC-MoveToShelf-ChangeStatus'),
    },
  ];
  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={
                SortInventoryBy === headCell.id ? SortInventory : undefined
              }
            >
              <TableSortLabel
                active={
                  SortInventoryBy === headCell.id && SortInventory !== false
                }
                direction={
                  SortInventoryBy === headCell.id && SortInventory !== false
                    ? SortInventory
                    : 'asc'
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
    </>
  );
};
interface ShelfProps {
  id: string;
  productId: number;
  model: string;
  description: string;
  serialNumber: string;
  status: string;
}
export const Shelf: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { shelf, loading } = useSelector((state: any) => state.ShelfReducer);
  const { state }: any = useLocation();
  const [SortInventory, setNextInventoryState] = useState<SortInventory>(false);
  const [SortInventoryBy, setInventoryBy] =
    useState<keyof InventoryModel>('id');
  const [searchInventoryList, setsearchInventoryList] = useState<any>('');
  const [searchStatus] = useState<any>('');

  const pageLimit: number = 10;
  const [page, setPage] = useState<number>(1);
  const facilityId = state?.facilityId;

  const userId = state?.userId
    ? state?.userId
    : secureLocalStorage.getItem('userId');
  const [isSessionExpire, setIsSessionExpire] = useState<boolean>(false);
  const notify = (message: any) => {
    toast.error(message, { position: 'top-center' });
  };
  const handleRequestSort = (event: any, property: keyof InventoryModel) => {
    const isCurrentAsc =
      SortInventoryBy === property && SortInventory === 'asc';
    const isCurrentDesc =
      SortInventoryBy === property && SortInventory === 'desc';
    if (isCurrentAsc) {
      setNextInventoryState('desc');
    } else if (isCurrentDesc) {
      setNextInventoryState(false);
    } else {
      setNextInventoryState('asc');
    }

    setInventoryBy(property);
  };
  const InventoryMatchesSearchList = (value: string) =>
    value.toLowerCase().indexOf(searchInventoryList.toLowerCase()) !== -1;

  const filteredshelf = shelf.filter(
    (data: ShelfProps) =>
      InventoryMatchesSearchList(data?.model) ||
      InventoryMatchesSearchList(data?.serialNumber)
  );

  const getInventories = async () => {
    try {
      dispatch(getAllShelfAction({ facilityId, t }));
    } catch (error) {
      notify('Something went wrong');
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchStatus, searchInventoryList]);
  useEffect(() => {
    if (state?.session === localStorage.getItem('session')) {
      getInventories();
    } else {
      navigate(`/users?facilityId=${secureLocalStorage.getItem('facilityId')}`);
    }
  }, []);

  const handleMoveToShelf = (serialNumber: string) => {
    if (cookies.get('accessToken')) {
      inventoryMoveToShelf(facilityId, serialNumber, userId)
        .then(() => {
          getInventories();
        })
        .catch((error) => notify(error?.response?.data?.message));
    } else {
      setIsSessionExpire(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          pt: 4,
          justifyContent: 'space-between',
          maxWidth: '100%',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2} md={4}>
            <Typography
              variant='h5'
              component='h5'
              sx={{ textAlign: 'left', width: 'fit-content' }}
            >
              {t('NewLC-OrderList-Inventory')} -{' '}
              {t('LCInventory-MoveToShelf-Title')}
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{ overflowX: 'auto' }}>
          {!cookies.get('accessToken') && (
            <SessionExpireModal
              navigateTo='userLogin'
              isSessionExpire={!cookies.get('accessToken')}
            />
          )}
          <Table
            sx={{
              minWidth: 650,
              mt: 5,
              bInventoryCollapse: 'separate',
              bInventorySpacing: '0 6px',
              bInventory: 'none',
              backgroundColor: '#e7eef7',
              overflowX: 'auto',
              tr: {
                backgroundColor: 'white',
                paddingTop: 0,
                paddingBottom: 0,
                margin: 0,
              },
              th: {
                backgroundColor: '#f5f6fa',
                color: '#6a859e',
                height: 40,
                paddingTop: 0,
                paddingBottom: 0,
                margin: 0,
                textAlign: 'left !important',
                verticalAlign: 'middle',
              },
              td: {
                textAlign: 'left',
                padding: 2,
                verticalAlign: 'middle',
              },
              '& th:first-child, td:first-child': {
                bInventoryTopLeftRadius: 10,
                bInventoryBottomLeftRadius: 10,
              },
              '& th:last-child, td:last-child': {
                bInventoryBottomRightRadius: 10,
                bInventoryTopRightRadius: 10,
              },
            }}
            aria-label='simple table'
          >
            <EnhancedTableHead
              SortInventory={SortInventory}
              SortInventoryBy={SortInventoryBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredshelf.length > 0 ? (
                stableSort(
                  filteredshelf,
                  getComparator(SortInventory, SortInventoryBy)
                )
                  .slice(
                    (page - 1) * pageLimit,
                    (page - 1) * pageLimit + pageLimit
                  )
                  .map((inventory: any) => (
                    <TableRow hover key={Number(inventory.id)}>
                      <TableCell>{inventory.model}</TableCell>
                      <TableCell>{inventory.serialNumber}</TableCell>
                      <TableCell sx={{ padding: '20px !important' }}>
                        {inventory.status === 'Delivered' && (
                          <Button
                            onClick={() => {
                              handleMoveToShelf(inventory.serialNumber);
                            }}
                            sx={{
                              bInventoryRadius: 1.5,
                              py: 0.37,
                              px: 1,
                              width: 'fit-content',
                              display: 'inline',
                              backgroundColor: '#4a6b89',
                              color: '#edf0f3',
                              textTransform: 'initial',
                              m: 0,
                              '&:hover': {
                                background: '#6087ab',
                              },
                            }}
                          >
                            {t('LCInventory-ListInventoryItems-ShelfBtn')}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableCell
                  colSpan={5}
                  sx={{ mt: 2, mb: 3, ml: 3, textAlign: 'center !important' }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: '#d32f2f' }} />
                  ) : (
                    searchStatus !== '' ||
                    (searchInventoryList !== '' &&
                      t('NewLC-OrderList-NoMatchingRecord'))
                  )}
                  {!loading &&
                    searchStatus === '' &&
                    searchInventoryList === '' &&
                    `${t('NewLCOP-No')} ${t('NewLC-OrderList-Inventory')}`}
                </TableCell>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm>
            <Button
              sx={{
                backgroundColor: '#dfe1e6',
                textTransform: 'inherit',
                bInventoryRadius: 2,
                bInventory: 'none',
                color: '#172B4D',
                m: '1% 1%',
                p: 0.8,
                float: 'right',
                minWidth: 115,
                '&:hover': {
                  background: '#C1C7D0',
                },
              }}
              onClick={() => navigate(-1)}
            >
              {t('BackPagePrompt')}
            </Button>
          </Grid>
          <Grid>
            {filteredshelf.length > 0 && (
              <TablePagination
                page={page}
                pages={filteredshelf.length}
                onChange={setPage}
                pageLimit={pageLimit}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {(!cookies.get('accessToken') || isSessionExpire) && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
    </>
  );
};
