import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid, Box, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import secureLocalStorage from 'react-secure-storage';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Cookies } from 'react-cookie';
import { InventoryTable } from '../../components/InventoryTable/InventoryTable';
import { SessionExpireModal } from '../../components/SessionExpireModal/SessionExpireModal';
import { getAllInventoryAction } from '../../common/components/Redux/Actions/InventoryAction';
import { ScanInventoryModal } from '../../components/ScanInventoryModal/ScanInventoryModal';
import beep from '../../assets/audio/Barcode-scanner-beep-sound.mp3';

const notify = (message: string) => {
  toast.error(message, { position: 'top-center' });
};

interface InventoryProps {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  status: string;
}
interface InventoryReducerProps {
  inventoryReducer: () => void;
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const Inventory: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { inventory, loading } = useSelector(
    (state: InventoryReducerProps | any) => state.InventoryReducer
  );
  const { state }: any = useLocation();
  const cookies = new Cookies();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isScanInventoryModalOpen, setIsScanInventoryModalOpen] =
    useState<boolean>(false);
  const [audio, setAudio] = React.useState<any>();

  React.useEffect(() => {
    const audioTag = new Audio(beep);
    audioTag.autoplay = true;
    audioTag.muted = true;
    setAudio(audioTag);
  }, []);

  const handleScanModalOpen = () => {
    setIsScanInventoryModalOpen(true);
    audio.muted = true;
    audio.src = beep;
  };

  const playBeepSound = () => {
    audio.muted = false;
    audio.src = beep;
  };

  const handleChange =
    (panel: string, isDisable: boolean = false) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (isDisable) setExpanded(false);
      else setExpanded(newExpanded ? panel : false);
    };
  const facilityId = state?.facilityId
    ? state?.facilityId
    : secureLocalStorage.getItem('facilityId');

  const groupBy = (dataArray: any[], key: string) =>
    dataArray.reduce((group, data) => {
      const groupByKey = data[key];
      group[groupByKey] = group[groupByKey] ?? []; // eslint-disable-line no-param-reassign
      group[groupByKey].push(data);
      return group;
    }, {});

  const getInventory = async () => {
    try {
      dispatch(getAllInventoryAction({ facilityId, t }));
    } catch (error: any) {
      notify(error?.response?.data?.message);
    }
  };
  const handleScanModalClose = (isSuccess: boolean) => {
    if (isSuccess) {
      getInventory();
    }
    setIsScanInventoryModalOpen(false);
  };

  const inventoryInTransit = groupBy(
    inventory?.filter((i: InventoryProps) => i.status === 'In Transit'),
    'productId'
  );

  const inventoryDelivered = groupBy(
    inventory?.filter((i: InventoryProps) => i.status === 'Delivered'),
    'productId'
  );

  const inventoryOnShelf = groupBy(
    inventory?.filter((i: InventoryProps) => i.status === 'On Shelf'),
    'productId'
  );
  useEffect(() => {
    if (state?.session === localStorage.getItem('session')) {
      getInventory();
    } else {
      navigate(`/users?facilityId=${secureLocalStorage.getItem('facilityId')}`);
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          pt: 4,
          justifyContent: 'center',
          maxWidth: '100%',
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
          }}
        >
          <Grid item xs={12} mb={5}>
            <Typography
              variant='h5'
              component='h5'
              sx={{ textAlign: 'left', width: 'fit-content' }}
            >
              {t('InventoryManagement-StockStatus-SectionTitle')}
            </Typography>
          </Grid>
          {inventory.length > 0 && !loading ? (
            <>
              {inventory?.filter(
                (i: InventoryProps) => i.status === 'In Transit'
              ).length > 0 ? (
                <Grid item xs={12} sm={9}>
                  <Accordion
                    expanded={expanded === 'intransitPanel'}
                    onChange={handleChange(
                      'intransitPanel',
                      inventory?.filter(
                        (i: InventoryProps) => i.status === 'In Transit'
                      ).length === 0
                    )}
                  >
                    <AccordionSummary
                      aria-controls='intransitPanel-content'
                      id='intransitPanel-header'
                    >
                      <Typography
                        variant='h6'
                        component='h6'
                        sx={{
                          textAlign: 'left',
                          width: 'fit-content',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('InTransitStatus')} (
                        {t(
                          'InventoryManagement-StockStatus-CountHeaderTemplate'
                        )}
                        :
                        {
                          inventory?.filter(
                            (i: InventoryProps) => i.status === 'In Transit'
                          ).length
                        }
                        )
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <InventoryTable inventoryData={inventoryInTransit} />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ) : (
                <div />
              )}
              {inventory?.filter(
                (i: InventoryProps) => i.status === 'Delivered'
              ).length > 0 ? (
                <Grid item xs={12} sm={9}>
                  <Accordion
                    expanded={expanded === 'deliveredPanel'}
                    onChange={handleChange(
                      'deliveredPanel',
                      inventory?.filter(
                        (i: InventoryProps) => i.status === 'Delivered'
                      ).length === 0
                    )}
                  >
                    <AccordionSummary
                      aria-controls='deliveredPanel-content'
                      id='deliveredPanel-header'
                    >
                      <Typography
                        variant='h6'
                        component='h6'
                        sx={{
                          textAlign: 'left',
                          width: 'fit-content',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('DeliveredStatus')} (
                        {t(
                          'InventoryManagement-StockStatus-CountHeaderTemplate'
                        )}
                        :
                        {
                          inventory?.filter(
                            (i: InventoryProps) => i.status === 'Delivered'
                          ).length
                        }
                        )
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <InventoryTable inventoryData={inventoryDelivered} />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ) : (
                <div />
              )}
              {inventory?.filter((i: InventoryProps) => i.status === 'On Shelf')
                .length > 0 ? (
                <Grid item xs={12} sm={9}>
                  <Accordion
                    expanded={expanded === 'onShelfPanel'}
                    onChange={handleChange(
                      'onShelfPanel',
                      inventory?.filter(
                        (i: InventoryProps) => i.status === 'On Shelf'
                      ).length === 0
                    )}
                  >
                    <AccordionSummary
                      aria-controls='onShelfPanel-content'
                      id='onShelfPanel-header'
                    >
                      <Typography
                        variant='h6'
                        component='h6'
                        sx={{
                          textAlign: 'left',
                          width: 'fit-content',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('OnShelfStatus')} (
                        {t(
                          'InventoryManagement-StockStatus-CountHeaderTemplate'
                        )}
                        :
                        {
                          inventory?.filter(
                            (i: InventoryProps) => i.status === 'On Shelf'
                          ).length
                        }
                        )
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <InventoryTable inventoryData={inventoryOnShelf} />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ) : (
                <div />
              )}
            </>
          ) : (
            <Grid
              xs={12}
              display='flex'
              justifyContent='center'
              fontWeight={500}
            >
              {loading ? (
                <CircularProgress sx={{ color: '#d32f2f' }} />
              ) : (
                'No Inventory Found'
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={9}
            sx={{
              justifyContent: 'center',
              mx: 'auto',
              mt: 1,
            }}
          >
            <Button
              sx={{
                backgroundColor: '#4a6b89',
                textTransform: 'inherit',
                borderRadius: 2,
                border: 'none',
                color: ' white',
                m: '1% 1%',
                p: 0.8,
                float: 'right',
                minWidth: 100,
                '&:hover': {
                  background: '#6087ab',
                },
              }}
              onClick={handleScanModalOpen}
            >
              Scan To Shelf
            </Button>
            <Button
              sx={{
                backgroundColor: '#dfe1e6',
                textTransform: 'inherit',
                borderRadius: 2,
                border: 'none',
                color: '#172B4D',
                m: '1% 1%',
                p: 0.8,
                float: 'right',
                minWidth: 100,
                '&:hover': {
                  background: '#C1C7D0',
                },
              }}
              onClick={() => navigate(-1)}
            >
              {t('BackPagePrompt')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {isScanInventoryModalOpen && (
        <ScanInventoryModal
          isScanInventoryModalOpen={isScanInventoryModalOpen}
          onClose={handleScanModalClose}
          playBeepSound={playBeepSound}
        />
      )}
      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
    </>
  );
};

export default Inventory;
