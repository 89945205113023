import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import image from './image.jpg';
import logo from '../../assets/images/logo-bpd.png';
import userIcon from '../../assets/images/usericon.png';
import { getHospitalName, getUserList } from '../../services/UserService';
import 'react-toastify/dist/ReactToastify.css';
import { changeI18nLanguage } from '../../common/utils/Utils';
import { NewUpdateVersionModal } from '../../components/NewUpdateVersionModal/NewUpdateVersionModal';
import { badge } from '../../common/utils/Theme';

interface User {
  id: number;
  displayName: string;
  active: boolean;
  emailAddress: string;
  phoneNumber: string;
  phoneCountryCode: string;
  preferredLang: string;
}
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
export const UserList: FunctionComponent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const [userList, setUserList] = useState<User[]>([]);
  const [hospitalName, setHospitalName] = useState<string>('');
  const facilityId: number | any = Number(useQuery().get('facilityId'))
    ? Number(useQuery().get('facilityId'))
    : -1;
  const notify = (message: any) => {
    toast.error(message, { position: 'top-center' });
  };
  const { serviceWorkerUpdated } = useSelector(
    (state: any) => state.ServiceWorkerReducer
  );
  const getUsers = async (facilityUserId: number) => {
    if (facilityId !== -1) {
      try {
        const data = await getUserList(facilityUserId);
        setUserList(data);
        setLoading(false);
        setIsError(false);
        const hospitalDetail = await getHospitalName(facilityUserId);
        if (data) {
          secureLocalStorage.setItem('facilityId', facilityId);
        }
        secureLocalStorage.setItem('programOptIn', hospitalDetail?.programOptIn);
        secureLocalStorage.setItem('hospitalName', hospitalDetail.name);
        setHospitalName(hospitalDetail?.name);
      } catch (error: any) {
        setIsError(true);
        if (error?.response?.data?.status !== 'NOT_FOUND') {
          notify(error?.response?.data?.message);
        } else {
          notify(error?.response?.data?.message);
        }
      }
    } else {
      setLoading(true);
      setIsError(true);
    }
  };

  const handleUserLogin = (id: number | any, displayName: string) => {
    secureLocalStorage.setItem('userId', id);
    secureLocalStorage.setItem('userName', displayName);
    navigate('/userLogin', {
      state: { userId: id, userName: displayName, facilityId, hospitalName },
    });
  };
  useEffect(() => {
    secureLocalStorage.setItem('language', 'English');
    changeI18nLanguage('English');
    getUsers(facilityId);
  }, []);

  return (
    <>
      <Box
        sx={
          process.env.REACT_APP_CUR_ENV === 'DEV' ||
          process.env.REACT_APP_CUR_ENV === 'UAT'
            ? badge
            : null
        }
      >
        <Box
          className='badge'
          sx={{
            width: '100%',
            display: 'block',
            m: 'auto',
            textAlign: 'center',
            overflow: 'hidden',
            pl: 0,
          }}
        >
          <ToastContainer />
          <Grid
            container
            columnSpacing={2}
            sx={{ ml: -1, minHeight: { xs: 400, sm: '100vh' } }}
          >
            <Grid
              item
              xs={6}
              sx={{
                width: '-webkit-fill-available',
                backgroundImage: `url(${image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: { xs: 'none', sm: 'block' },
              }}
            />
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                p: { xs: 0, sm: '24px !important' },
                m: 0,
                backgroundColor: 'white',
                pl: { xs: '0px !important' },
              }}
            >
              <Grid sx={{ mt: 4 }}>
                <img
                  src={logo}
                  style={{ width: ' 100%', maxWidth: '400px', padding: '10px' }}
                />
                <Typography
                  variant='h5'
                  sx={{ mt: 3, mb: 2, color: '#4a6b89', ml: 2, mr: 2 }}
                >
                  {t('OutPatient-Landing-Title')} to {hospitalName}
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 300, ml: 2, mr: 2 }}
                >
                  {t('InPatient-LCLogin-SectionTitle')}
                </Typography>
              </Grid>
              <Grid>
                <Typography sx={{ mt: 3, mb: 2, fontSize: 15 }}>
                  {t('NewLC-SelectLC-SelectLC')}
                </Typography>
              </Grid>
              {userList.length > 0 ? (
                <Grid container sx={{ justifyContent: { xs: 'left' } }}>
                  {userList.map(
                    (user: User) =>
                      user.active === true && (
                        <Grid
                          key={user.id}
                          item
                          sm={3}
                          sx={{
                            p: 0.6,
                            textDecoration: 'none',
                          }}
                        >
                          <Card
                            onClick={() =>
                              handleUserLogin(user.id, user.displayName)
                            }
                            sx={{
                              borderRadius: 4,
                              backgroundColor: '#eef2f5',
                              boxShadow: 'none',
                            }}
                          >
                            <CardMedia sx={{ mt: 2 }}>
                              <img src={userIcon} style={{ width: '20%' }} />
                            </CardMedia>
                            <CardContent sx={{ pb: 2, p: 0.6 }}>
                              {user.displayName}
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                  )}
                </Grid>
              ) : (
                <Grid
                  sx={{
                    justifyContent: 'center',
                    fontWeight: '500',
                    color: 'red',
                  }}
                >
                  {loading && !isError ? (
                    <CircularProgress sx={{ color: '#d32f2f' }} />
                  ) : (
                    !isError && 'No Consultants assigned to this location.'
                  )}
                  {loading && isError && t('NewLC-SelectLC-FacilityNotExist')}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
