import { useSelector } from 'react-redux';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableContainer,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { InventoryModel } from '../../models/Inventory';
import { SessionExpireModal } from '../SessionExpireModal/SessionExpireModal';
import { NewUpdateVersionModal } from '../NewUpdateVersionModal/NewUpdateVersionModal';

interface InventoryTableProps {
  inventoryData: any;
}

function createData(data: InventoryModel[]) {
  return {
    model: data[0].model,
    totalInventory: data.length,
    data,
  };
}

const Row = (props: { row: ReturnType<typeof createData> }) => {
  const { t } = useTranslation();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '50px' }}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
          {(row as unknown as InventoryModel[])[0].model} (
          {t('InventoryManagement-StockStatus-CountHeaderTemplate')}:
          {(row as unknown as InventoryModel[]).length})
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: 'bold', maxWidth: '40px !important ' }}
                    >
                      {t('DataExportFiles-Details-ModelPrompt')}
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', maxWidth: '40px !important ' }}
                    >
                      {t('Archive-Index-SerialNumberColumnHeader')}
                    </TableCell>
                    <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                      {t('LCInventory-ListInventoryItems-StatusColumnTitle')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(row as unknown as InventoryModel[]).map(
                    (item: InventoryModel) => (
                      <TableRow key={item.id}>
                        <TableCell component='th' scope='row'>
                          {item.model}
                        </TableCell>
                        <TableCell>{item.serialNumber}</TableCell>
                        <TableCell align='right'>
                          {item.status === 'On Shelf'
                            ? t('OnShelfStatus')
                            : null}
                          {item.status === 'Delivered'
                            ? t('DeliveredStatus')
                            : null}
                          {item.status === 'In Transit'
                            ? t('InTransitStatus')
                            : null}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const InventoryTable: FunctionComponent<InventoryTableProps> = ({
  inventoryData,
}) => {
  const cookies = new Cookies();
  const { state }: any = useLocation();
  const { serviceWorkerUpdated } = useSelector(
    (serviceWorkerState: any) => serviceWorkerState.ServiceWorkerReducer
  );
  return (
    <>
      <TableContainer sx={{ maxHeight: '400px' }}>
        <Table
          sx={{
            minWidth: 650,
            borderCollapse: 'separate',
            borderSpacing: '0 6px',
            border: 'none',
            backgroundColor: '#e7eef7',
            overflowX: 'auto',
            tr: {
              backgroundColor: 'white',
              paddingTop: 0,
              paddingBottom: 0,
              margin: 0,
            },
            th: {
              backgroundColor: '#f5f6fa',
              color: '#6a859e',
              height: 40,
              paddingTop: 0,
              paddingBottom: 0,
              margin: 0,
              textAlign: 'left !important',
              verticalAlign: 'middle',
            },
            td: {
              textAlign: 'left',
              padding: 2,
              verticalAlign: 'middle',
              maxWidth: '40px',
            },
            '& th:first-child': {
              maxWidth: '40px',
            },
            '& th:first-child, td:first-child': {
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            '& th:last-child, td:last-child': {
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
            },
          }}
          aria-label='simple table'
        >
          <TableBody
            sx={{
              height: 'max-content',
            }}
          >
            {Object.keys(inventoryData).map((row: any) => (
              <Row key={inventoryData[row]} row={inventoryData[row]} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!cookies.get('accessToken') && (
        <SessionExpireModal
          navigateTo='userLogin'
          isSessionExpire={!cookies.get('accessToken')}
        />
      )}
      {state?.session !== localStorage.getItem('session') && (
        <SessionExpireModal
          navigateTo='userList'
          isSessionExpire={state?.session !== localStorage.getItem('session')}
        />
      )}
      {serviceWorkerUpdated && <NewUpdateVersionModal />}
    </>
  );
};
