import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllInventory } from '../../../../services/InventoryService';
import { StoreAction } from '../Action';

interface InventoryProp {
  facilityId: number;
  t: Function;
}
export const getAllInventoryAction = createAsyncThunk(
  '',
  async ({ facilityId, t }: InventoryProp, { dispatch, getState }) => {
    const state: any = getState();
    const data = state.InventoryReducer.inventory;
    dispatch({
      type: StoreAction.SET_INVENTORY_LOADING,
      payload: true,
    });
    let inventoryData = [];
    const inventory = await getAllInventory(facilityId, t);

    if (
      (inventory.length === 0 && data.length === 0) ||
      inventory.length !== 0
    ) {
      inventoryData = inventory;
    } else {
      inventoryData = data;
    }
    return dispatch({
      type: StoreAction.GET_INVENTORY,
      payload: inventoryData,
    });
  }
);
