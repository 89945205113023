import { Button, Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface TablePaginationProps {
  page: number;
  pages: number;
  onChange: (page: number) => void;
  pageLimit: number;
}

const pageLink = {
  position: 'relative',
  display: 'block',
  textDecoration: 'none',
  border: '1px solid #dee2e6',
  transition:
    'color 0.15s ease-in-out, background-color 0.15s ease-in-out,border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
};

const pageicon = {
  color: '#5e7b96',
  fontWeight: 800,
  border: 'none !important',
  backgroundColor: '#e7eef7 !important',
  padding: '4px 10px',
};

export const TablePagination: FunctionComponent<TablePaginationProps> = ({
  page,
  pages,
  onChange,
  pageLimit,
}) => {
  const numPages = Math.ceil(pages / pageLimit);
  const { t } = useTranslation();
  const renderPageBlocks = () => (
    <li>
      <Button
        type='button'
        sx={{
          pageLink,
          backgroundColor: '#e6863e',
          color: 'white',
          borderRadius: '10px',
          fontWeight: 700,
          padding: '4px 10px',
          minWidth: 25,
          marginLeft: 1,
          '&:hover': {
            background: '#ef9047',
          },
        }}
      >
        {page}
      </Button>
    </li>
  );

  const goToPrevPage = () => {
    if (page > 1) {
      onChange(page - 1);
    }
  };

  const goToNextPage = () => {
    if (page < numPages) {
      onChange(page + 1);
    }
  };
  const goToFirstPage = () => {
    if (page > 1) {
      onChange(1);
    }
  };

  const goToLastPage = () => {
    if (page < numPages) {
      onChange(numPages);
    }
  };

  const goToPage = (pageNo: number) => {
    if (page < numPages) {
      onChange(pageNo);
    }
  };
  const renderPrevPageBlocks = () => (
    <>
      <li>
        <Button
          disabled={page === 1}
          type='button'
          onClick={goToFirstPage}
          sx={{ pageLink, pageicon, minWidth: 25, marginLeft: 1 }}
        >
          &#8810;
        </Button>
      </li>
      <li>
        <Button
          disabled={page === 1}
          type='button'
          onClick={goToPrevPage}
          sx={{ pageLink, pageicon, minWidth: 25, marginLeft: 1 }}
        >
          &lt;
        </Button>
      </li>
      {page > 1 ? (
        <li>
          <Button
            type='button'
            onClick={goToPrevPage}
            sx={{
              position: 'relative',
              display: 'block',
              textDecoration: 'none',
              border: '1px solid #dee2e6',
              transition:
                'color 0.15s ease-in-out, background-color 0.15s ease-in-out,border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              color: '#5e7b96',
              borderRadius: '10px',
              fontWeight: 700,
              padding: '4px 10px',
              backgroundColor: '#efefef',
              minWidth: 25,
              marginLeft: 1,
            }}
          >
            {page - 1}
          </Button>
        </li>
      ) : null}
    </>
  );

  const renderNextPageBlocks = () => {
    let pendingPages = numPages - page;
    pendingPages = pendingPages > 3 ? 3 : pendingPages;
    return (
      <>
        {page < numPages
          ? Array.from(new Array(pendingPages)).map((p, index) => (
              <li key={p}>
                <Button
                  onClick={() => goToPage(page + (index + 1))}
                  sx={{
                    position: 'relative',
                    display: 'block',
                    textDecoration: 'none',
                    border: '1px solid #dee2e6',
                    transition:
                      'color 0.15s ease-in-out, background-color 0.15s ease-in-out,border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                    color: '#5e7b96',
                    borderRadius: '10px',
                    fontWeight: 700,
                    padding: '4px 10px',
                    backgroundColor: '#efefef',
                    minWidth: 25,
                    marginLeft: 1,
                  }}
                >
                  {page + (index + 1)}
                </Button>
              </li>
            ))
          : null}
        <li>
          <Button
            type='button'
            onClick={goToNextPage}
            disabled={page === numPages}
            sx={{ pageLink, pageicon, minWidth: 25, marginLeft: 1 }}
          >
            &gt;
          </Button>
        </li>
        <li>
          <Button
            type='button'
            onClick={goToLastPage}
            disabled={page === numPages}
            sx={{ pageLink, pageicon, minWidth: 25, marginLeft: 1 }}
          >
            &#8811;
          </Button>
        </li>
      </>
    );
  };
  return (
    <Grid
      aria-label='Page navigation example'
      sx={{ width: 'inherit !important' }}
    >
      <Grid
        component='li'
        sx={{
          marginTop: 3,
          display: { xs: 'flex', sm: 'none' },
          justifyContent: 'flex-end',
        }}
      >
        {t('NewLC-OrderList-Showing')} {page * pageLimit - pageLimit + 1}-
        {pages < page * pageLimit ? pages : page * pageLimit} of {pages}
      </Grid>
      <ul
        style={{
          justifyContent: 'flex-end',
          padding: '10px',
          display: 'flex',
          paddingRight: 0,
          listStyle: 'none',
        }}
      >
        <Grid
          component='li'
          sx={{
            marginTop: 0.6,
            display: { xs: 'none', sm: 'block' },
          }}
        >
          {t('NewLC-OrderList-Showing')} {page * pageLimit - pageLimit + 1}-
          {pages < page * pageLimit ? pages : page * pageLimit} of {pages}
        </Grid>
        {renderPrevPageBlocks()}
        {renderPageBlocks()}
        {renderNextPageBlocks()}
      </ul>
    </Grid>
  );
};
