import { getI18n } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { englishI18nLanguage, spanishI18nLanguage } from './Constants';

export const getI18nLanguage = (language: string) => {
  if (language === 'Espanol') {
    return spanishI18nLanguage;
  }

  return englishI18nLanguage;
};

export const changeI18nLanguage = (language: string) => {
  const selectedI18nLanguage = getI18nLanguage(language);
  const i18n = getI18n();

  if (i18n.language !== selectedI18nLanguage) {
    i18n.changeLanguage(selectedI18nLanguage);
  }
};
