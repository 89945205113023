import { StoreAction } from '../Action';

const initialState = {
  orders: [],
  loading: true,
};
const OrdersReducer = (state = initialState, { type, payload }: any = {}) => {
  switch (type) {
    case StoreAction.GET_ORDERS:
      return {
        ...state,
        orders: payload,
        loading: false,
      };
    case StoreAction.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
export default OrdersReducer;
