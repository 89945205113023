import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllInventory } from '../../../../services/InventoryService';
import { StoreAction } from '../Action';

interface InventoryProp {
  facilityId: number;
  t: Function;
}

interface InventoryProps {
  id: string;
  productId: number;
  model: string;
  description: string;
  serialNumber: string;
  status: string;
}
export const getAllShelfAction = createAsyncThunk(
  '',
  async ({ facilityId, t }: InventoryProp, { dispatch }) => {
    const shelf = await getAllInventory(facilityId, t);
    const newData = shelf?.filter(
      (d: InventoryProps) => d?.status === 'Delivered'
    );

    return dispatch({
      type: StoreAction.GET_SHELF,
      payload: newData,
    });
  }
);
