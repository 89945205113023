import { useSelector } from 'react-redux';
import { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

export const NewUpdateVersionModal: FunctionComponent = () => {
  const { serviceWorkerRegistration } = useSelector(
    (state: any) => state.ServiceWorkerReducer
  );

  const reloadPage = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.replace(
            `${
              window.location.origin
            }/users?facilityId=${secureLocalStorage.getItem('facilityId')}`
          );
        }
      });
    }
  };
  return (
    <Dialog open sx={{ borderRadius: '8px !important', border: 'none' }}>
      <DialogContent>
        <DialogContentText sx={{ color: 'black' }}>
          A new version of this application is available
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            background: '#E6863E !important',
            color: '#fff',
            textTransform: 'capitalize',
            boxShadow: 'none !important',
            fontSize: '15px',
            fontWeight: '700 !important',
            px: 2,
            borderRadius: 2,
          }}
          onClick={() => reloadPage()}
        >
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUpdateVersionModal;
