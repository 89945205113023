import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Order } from '../../../../models/Order';
import { getAllOrders } from '../../../../services/OrderService';
import { StoreAction } from '../Action';
import 'react-toastify/dist/ReactToastify.css';

interface OrderProps {
  facilityId: number;
  t: Function;
}
const notify = (message: string) => {
  toast.error(message, { position: 'top-center' });
};
export const getAllOrdersAction = createAsyncThunk(
  '',
  async ({ facilityId, t }: OrderProps, { dispatch, getState }) => {
    const state: any = getState();
    const data = state.OrdersReducer.orders;
    dispatch({
      type: StoreAction.SET_LOADING,
      payload: true,
    });
    const orders = await getAllOrders(facilityId);
    let newData: Array<{}> = [];
    if (
      (orders?.data.length === 0 && data.length === 0) ||
      orders?.data.length !== 0
    ) {
      orders?.data.map((d: any) =>
        newData.push({
          ...d,
          dateOfBirth: moment(d.dateOfBirth).format('MM/DD/YYYY'),
        })
      );
    } else {
      newData = data;
    }
    if (orders?.error) {
      if (
        orders?.error?.message !== 'timeout of 20000ms exceeded' &&
        orders?.error?.message !== 'Network Error'
      ) {
        notify(orders?.error?.response?.data?.message);
      } else {
        notify(t('NewLCOP-NetworkError'));
      }
    }
    return dispatch({
      type: StoreAction.GET_ORDERS,
      payload: newData,
    });
  }
);
export const createOrderAction = createAsyncThunk(
  '',
  async (order: Order, { dispatch, getState }) => {
    const state: any = getState();
    const newOrders: Array<{}> = state.OrdersReducer.orders;
    newOrders.unshift({
      ...order,
      dateOfBirth: moment(order?.dateOfBirth).format('MM/DD/YYYY'),
    });
    return dispatch({
      type: StoreAction.GET_ORDERS,
      payload: newOrders,
    });
  }
);
