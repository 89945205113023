import { Button, Grid, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { FunctionComponent } from 'react';

interface TakePhotoProps {
  name: string;
  onChange: Function;
  ariaLabelledby: string;
}
const btnStyle = {
  color: 'white',
  backgroundColor: '#4a6a89',
  borderRadius: '5px',
  padding: '8px 17px',
  fontSize: '12px',
  background: '#4a6a89',
  textTransform: 'capitalize',
  '&:hover': {
    background: '#4a6a89',
    boxShadow: 'none',
  },
  display: 'flex',
  marginBottom: 1.5,
};

export const TakePhoto: FunctionComponent<TakePhotoProps> = ({
  name,
  onChange,
  ariaLabelledby,
}) => {
  const { t } = useTranslation();
  return (
    <Button sx={{ p: 0, width: '100%', justifyContent: 'flex-start' }}>
      <InputLabel htmlFor={name} id={ariaLabelledby} sx={btnStyle}>
        <CameraAltIcon sx={{ fontSize: '20px', marginRight: '8px' }} />
        <Grid
          component='span'
          sx={{ pt: '1px', fontSize: '12px', fontWeight: 500 }}
        >
          {t('NewLC-CreateOrder-TakePhoto')}
        </Grid>
      </InputLabel>
      <input
        id={name}
        name={name}
        type='file'
        accept='application/pdf, image/*'
        aria-labelledby={ariaLabelledby}
        hidden
        multiple
        onChange={(e) => {
          const clearInput = () => {
            e.target.value = '';
          };
          onChange(e, clearInput);
        }}
      />
    </Button>
  );
};
